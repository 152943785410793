import React, { useEffect, useState } from "react";
import style from "../../styles/searchComponent.module.scss";
import { FiShare } from "react-icons/fi";
import { FooterComponent, HeaderComponent } from "pages/landingPage/components";
import { OverviewTab } from "../components/overviewTab";
import { ReviewTab } from "../components/reviewTab";
import WhiteDot from "assets/svg/whiteDot.svg";
import Marked from "assets/svg/marked.svg";
import { SlPicture } from "react-icons/sl";
import { ReactComponent as WhiteImgIcon } from "assets/svg/img-placeholder-white.svg";
import {
    AuthHeaderText,
    PrimaryButton,
    PrimaryDropzone,
    PrimaryLoader,
} from "components/index";
import {
    useGetMyBusinessQuery,
    useGetOpenBusinessByIdQuery,
    usePostBusinessUploadMutation,
    useUpdateBusinessMutation,
} from "store/business";
import { useNavigate, useParams } from "react-router-dom";
import Rating from "react-rating-fix";
import { useModalProvider, usePageNotificationProvider } from "providers/index";
import {
    IBusinessAverageRatings,
    useGetBusinessReviewsAverageSummaryQuery,
    useGetBusinessReviewsQuery,
} from "store/review";
import { RiEditBoxLine } from "react-icons/ri";
import { useAuth } from "store/auth";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { useFormik } from "formik";
import { IBusinessUserInterface } from "pages/auth/business/business-Interface";
import { resolveApiError } from "utilities/errorHandling";
import { LoadingPopUp } from "pages/auth/business/business-upload";

const ModifiedRating = Rating as any;

export function BusinessPage() {
    const navigate = useNavigate();
    const { initModal } = useModalProvider();
    const { initNotification } = usePageNotificationProvider();
    const { user: storedUser } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(storedUser?.id || "");
    const { id } = useParams();
    const {
        data: business,
        refetch,
        isLoading,
    } = useGetOpenBusinessByIdQuery(id);
    const { data: reviews } = useGetBusinessReviewsQuery({
        id,
        query: { type: "business" },
    });
    const { data: businessReviewsAverageSummary } =
        useGetBusinessReviewsAverageSummaryQuery({
            id: id || "",
        });
    const [currentTab, setCurrentTab] = useState(1);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [isSameBusiness, setIsSameBusiness] = useState(false);
    const averageRatings = reviews?.data as unknown as IBusinessAverageRatings;
    const businessReviewsAndAverageRatings =
        businessReviewsAverageSummary?.data;
    const biz = business?.data;

    useEffect(() => {
        setIsSameBusiness(String(id) == String(businessInfo?.data[0]?.id));
        console.log(refetch);
    }, [id, biz, businessInfo?.data, businessReviewsAverageSummary?.data]);

    const [highlight, setHighlight] = useState(false);
    const handleSetHighlight = () => {
        setHighlight(true);
        setTimeout(() => {
            setHighlight(false);
        }, 3000);
    };

    async function checkImage(url: RequestInfo | URL) {
        const res = await fetch(url);
        const buff = await res.blob();

        return buff.type.startsWith("image/");
    }

    if (isLoading) {
        return <PrimaryLoader height={"90vh"} />;
    }

    const placeholder =
        "https://bbcincorp.com/assets/images/hk/home/background-form-banner.png";

    return (
        <div className="pt-[70px] md:pt-[80px]">
            {/*========== HEADER =========== */}
            <HeaderComponent role="general" />
            {/*========== END OF HEADER =========== */}
            <div
                style={{
                    color: "white",
                    background: `linear-gradient(180deg, rgba(57, 57, 57, 0) 0%, #070707 95.31%), url(${
                        biz?.businessBanner.includes("http")
                            ? biz?.businessBanner
                            : placeholder
                    }) no-repeat`,
                    backgroundSize: "cover",
                }}
                className={`relative ${style.backgroundImages}`}
            >
                {isSameBusiness && (
                    <button
                        onClick={() => setShowEditModal(!showEditModal)}
                        className="absolute top-5 md:right-5 md:left-auto left-5 bg-primary-500 p-2 rounded z-10"
                    >
                        <RiEditBoxLine className="text-white text-2xl" />
                    </button>
                )}
                <div className="container h-full">
                    <div
                        className={`${style.bckWords} h-full lg:justify-between justify-start py-5 pt-14 sm:py-14`}
                    >
                        <div className={style.nameAndDetails}>
                            <h1>{biz?.businessName}</h1>

                            {averageRatings?.averageRating ? (
                                <div className="text-gray-600 flex items-center gap-2 !ml-0 sm:mt-6 mt-3">
                                    <ModifiedRating
                                        // placeholderRating={3.5}
                                        readonly
                                        initialRating={
                                            averageRatings?.averageRating
                                        }
                                        emptySymbol={
                                            <HiOutlineStar className="h-6 w-6 me-2 text-[#FFC554]" />
                                        }
                                        fullSymbol={
                                            <HiStar className="h-6 w-6 me-2 text-[#FFA800]" />
                                        }
                                        fractions={2}
                                        // start={0}
                                        // stop={5}
                                        // step={5}
                                    />
                                    <p className="text-white text-base font-medium">
                                        {averageRatings?.averageRating.toFixed(
                                            1
                                        )}{" "}
                                        ({averageRatings?.reviewsCount} Reviews)
                                    </p>
                                </div>
                            ) : null}

                            <div className={style.verificationBCK}>
                                {biz?.claimed ? (
                                    <>
                                        <img alt="" src={Marked} />{" "}
                                        <p> Claimed</p>
                                    </>
                                ) : (
                                    <p>Not Claimed</p>
                                )}
                                {biz?.businessCategory?.map(
                                    (category: any, index: number) => (
                                        <>
                                            <img
                                                alt=""
                                                src={WhiteDot}
                                                className={style.dotImageBCK}
                                            />{" "}
                                            <p key={index}>{category?.name} </p>
                                        </>
                                    )
                                )}
                            </div>

                            {biz?.businessHours?.length ? (
                                <div className={style.businessTimeBCK}>
                                    <p className={style.blue}> Open </p>
                                    <p>
                                        {biz?.businessHours![0]?.openingTime} -{" "}
                                        {biz?.businessHours![0]?.closingTime}
                                    </p>
                                    <a
                                        href="#hours"
                                        className="btn-sm rounded-md text-primary-500 !bg-white !font-normal"
                                        onClick={handleSetHighlight}
                                    >
                                        View Hours
                                    </a>
                                </div>
                            ) : null}
                        </div>
                        <PrimaryButton
                            onClick={() =>
                                initModal((prev) => ({
                                    ...prev,
                                    businessPhotos: {
                                        payload: {
                                            businessImages: biz?.businessImages,
                                        },
                                    },
                                }))
                            }
                            className="btn-md !bg-[#ffffffcc] text-primary-500 !font-normal self-end"
                        >
                            <SlPicture className="me-2 h-3 w-3" />{" "}
                            <span>See All Photos </span>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
            <div
                style={{
                    boxShadow:
                        "0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005",
                }}
                className="py-4"
            >
                <div className="container">
                    <div className="flex justify-between items-center">
                        <h2 className=" text-4xl font-medium text-[#4d4d4d] md:block hidden ">
                            {biz?.businessName}
                        </h2>
                        <div
                            className={`${style.buttonsBusiness} md:flex-row flex-row-reverse`}
                        >
                            {/* <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <IoMdPhotos className="h-4 w-4 me-2" />
                Add photos
              </PrimaryButton> */}

                            <PrimaryButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        window.location.href
                                    );
                                    initNotification({
                                        message: "Link copied",
                                        scheme: "success",
                                    });
                                }}
                                className="btn-sm secondary-btn !font-normal"
                            >
                                <FiShare className="h-4 w-4" />
                                <span className="md:inline-block hidden ms-2">
                                    Share
                                </span>
                            </PrimaryButton>

                            {isSameBusiness ? (
                                <p className="text-sm text-gray-500 mt-3">
                                    This is what users see
                                </p>
                            ) : (
                                <PrimaryButton
                                    onClick={() => {
                                        localStorage.setItem(
                                            "suggestingBusinessId",
                                            id || ""
                                        );
                                        initModal((prev) => ({
                                            ...prev,
                                            suggestEdit: true,
                                        }));
                                    }}
                                    className="btn-sm secondary-btn !font-normal"
                                >
                                    <RiEditBoxLine className="h-4 w-4" />
                                    <span className="md:inline-block hidden ms-2">
                                        Suggest an edit
                                    </span>
                                </PrimaryButton>
                            )}

                            {/* <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <CiBookmarkPlus className="h-4 w-4 me-2" />
                Save
              </PrimaryButton>

              <PrimaryButton className="btn-sm secondary-btn !font-normal">
                <HiOutlinePlusSmall className="h-4 w-4 me-2" />
                Follow
              </PrimaryButton> */}
                            {!isSameBusiness && (
                                <PrimaryButton
                                    onClick={() => {
                                        navigate(
                                            `/review?business=${id}&name=${biz?.businessName}`
                                        );
                                    }}
                                    className="btn-sm primary-btn !font-normal"
                                >
                                    Write Review
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-8 lg:hidden block">
                <div className="flex justify-between items-center gap-4">
                    <button
                        onClick={() => setCurrentTab(1)}
                        className={`${
                            currentTab === 1
                                ? "border-b text-[#001C89] border-primary-500"
                                : "text-[#C2C2C2]"
                        } pb-3 w-1/2 border-0`}
                    >
                        Overview
                    </button>
                    <button
                        onClick={() => setCurrentTab(2)}
                        className={`${
                            currentTab === 2
                                ? "border-b text-[#001C89] border-primary-500"
                                : "text-[#C2C2C2]"
                        } pb-3 w-1/2 border-0`}
                    >
                        Reviews
                    </button>
                </div>
            </div>
            <div className="pt-9 pb-24">
                {currentTab === 1 && (
                    <OverviewTab
                        business={biz}
                        isSameBusiness={isSameBusiness}
                        reviewsAndRatings={businessReviewsAndAverageRatings}
                        highlight={highlight}
                    />
                )}
                {currentTab === 2 && (
                    <ReviewTab
                        business={biz}
                        reviewsAndRatings={businessReviewsAndAverageRatings}
                        highlight={highlight}
                    />
                )}
            </div>

            <FooterComponent />
            {showEditModal ? (
                <EditModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    refetch={refetch}
                    businessInfo={businessInfo?.data?.[0] || {}}
                    onClose={() => setShowEditModal(false)}
                />
            ) : null}
        </div>
    );
}

type TEditModal = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    businessInfo: any;
    onClose: () => void;
};

function EditModal({ onClose, setShow, refetch, businessInfo }: TEditModal) {
    const { initNotification } = usePageNotificationProvider();
    const [updateBusiness] = useUpdateBusinessMutation();
    const [files, setFiles] = useState<any>([]);
    const [submitting, setSubmitting] = useState(false);
    const [preview, setPreview] = useState<string>("");
    const [postBusinessUpload, { upLoading }] = usePostBusinessUploadMutation();
    const { handleSubmit } = useFormik({
        initialValues: {
            businessLogo: "",
        },
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });

    const initRequest = async (payload?: any) => {
        setSubmitting(true);
        const form = new FormData();
        form.append("file", files[0].data);
        form.append("type", "logo");

        postBusinessUpload(form)
            .unwrap()
            .then((res: any) => {
                if (res.error)
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                initNotification({
                    message: res.message,
                    scheme: "success",
                });
                const business = {
                    businessBanner: res.data?.imageUrl,
                };

                updateBusiness({ businessData: business, id: businessInfo.id })
                    .unwrap()
                    .then((res: any) => {
                        initNotification({
                            scheme: "success",
                            message: res.message,
                        });
                        setSubmitting(false);
                        refetch();
                        setShow(false);
                    })
                    .catch((err: any) => {
                        initNotification({
                            scheme: "error",
                            message:
                                resolveApiError(err) ||
                                err?.data?.message ||
                                err.error,
                        });
                        setSubmitting(false);
                    });
            })
            .catch((error: any) => {
                console.log(error);
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    useEffect(() => {
        const image = files[0]?.data;
        if (image) setPreview(URL.createObjectURL(image));
    }, [files]);

    return (
        <div
            onClick={onClose}
            className="fixed inset-0 bg-black/50 flex flex-col item-center justify-center overflow-hidden z-[4]"
        >
            <div className="h-full overflow-y-scroll py-20 pt-36">
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="bg-white min-h-full md:min-h-96 w-full max-w-2xl md:px-8 py-10 px-4 rounded-lg m-auto"
                >
                    <AuthHeaderText
                        title="Change your business banner"
                        headingClassName="text-black text-3xl font-medium mb-5"
                    />
                    <br />
                    <form onSubmit={handleSubmit} className="">
                        {!preview ? (
                            <PrimaryDropzone type="logo" setFile={setFiles} />
                        ) : (
                            <div className="">
                                <img
                                    src={preview}
                                    className="w-full h-80 object-contain"
                                    alt="preview"
                                />
                                <br />
                                <PrimaryButton
                                    type="reset"
                                    onClick={() => {
                                        setPreview("");
                                        setFiles([]);
                                    }}
                                    className="primary-btn py-6 px-10"
                                >
                                    <>Clear</>
                                </PrimaryButton>
                            </div>
                        )}

                        <div className="flex items-center gap-4 mt-10">
                            <PrimaryButton
                                type="submit"
                                className="primary-btn flex gap-4 py-6 px-10"
                                isLoading={submitting}
                            >
                                <WhiteImgIcon className="w-4 h-4 text-base" />
                                <p>Submit</p>
                            </PrimaryButton>

                            <button
                                onClick={onClose}
                                className="py-3 px-8 cursor-pointer hover:bg-gray-50 rounded-md"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                    {submitting && <LoadingPopUp />}{" "}
                </div>
            </div>
        </div>
    );
}
