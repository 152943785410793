import { FooterComponent, HeaderComponent } from "./components";
import { Redefine } from "./components/redefine";
import style from "../styles/company.module.scss";
import LadyWithBags from "assets/png/ladyCarryingBags.png";
import Suitman from "assets/png/BlackSuitMan.png";
import { PrimaryButton } from "components/index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Company = () => {
    return (
        <>
            <div style={{ paddingTop: "84px" }}>
                {/*========== HEADER =========== */}
                <HeaderComponent role="general" />
                {/*========== END OF HEADER =========== */}

                <section>
                    <div className="container">
                        <CompanyHero />
                        <AboutUs />
                        <JoinTheCommnunity />
                        <Redefine />
                    </div>
                </section>

                {/*========== FOOTER =========== */}
                <FooterComponent />
                {/*========== END OF FOOTER =========== */}
            </div>
        </>
    );
};

//!! ============== Company Hero COMPONENT ?? //
export function CompanyHero() {
    return (
        <>
            <div className={style.companyHeroText}>
                <h1>We are helping shoppers make smart choices</h1>
            </div>
        </>
    );
}

//!! ============== ABOUT US AND VISION COMPONENT ?? //
export function AboutUs() {
    return (
        <>
            <div className="container">
                <div className={style.aboutus}>
                    <div className={style.imageAboutUs}>
                        <img alt="" src={LadyWithBags} />
                    </div>
                    <div className={style.textAboutUs}>
                        <h1>About Us</h1>
                        <p>
                            TradeTracka is a powerful platform that harnesses
                            the capabilities of artificial intelligence to
                            analyze, verify, and curate customer reviews. We
                            have developed sophisticated algorithms that can
                            detect and filter out fake reviews, ensuring the
                            authenticity of the information available to our
                            users.{" "}
                        </p>
                        <br />
                        <p>
                            Our platform boasts of the largest community of
                            reviewers in Nigeria, covering a wide range of
                            industries and businesses. With TradeTracka, users
                            can access real experiences and opinions from their
                            fellow Nigerians, helping them make confident
                            purchasing decisions.
                        </p>
                    </div>
                </div>

                <div className={style.ourVision}>
                    <div className={style.ourVisionText}>
                        <h1>Our Vision</h1>
                        <p>
                            TradeTracka aims to become the leading review and
                            rating platform, trusted by online shoppers and
                            businesses across Africa for providing accurate,
                            reliable, and transparent information, fostering
                            trust, and enhancing customer experiences in the
                            digital marketplace.
                        </p>
                    </div>
                    <div className={style.ourVisionImage}>
                        <img alt="" src={Suitman} />
                    </div>
                </div>
            </div>
        </>
    );
}

//!! ============== JOIN THE COMMUNITY COMPONENT ?? //
export function JoinTheCommnunity() {
    return (
        <>
            <div className="container">
                <div className={style.joinCommunity}>
                    <div className={style.joinCommunityContent}>
                        <h1>Join the TradeTracka Community</h1>
                        <p>
                            Whether you are an online shopper seeking
                            trustworthy information or a business aiming to
                            strengthen your online presence, TradeTracka is here
                            for you. Join our community today and experience the
                            power of trust and credibility in Nigeria's digital
                            marketplace.
                        </p>
                        <PrimaryButton className="btn-lg secondary-btn me-3">
                            <Link to={"/sign-up/individual"}>Join Us</Link>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    );
}
