/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton } from "components/index";
import { PrimaryInput } from "components/inputs";
import { IBusinessUserInterface } from "./business-Interface";
import { reactLocalStorage } from "reactjs-localstorage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { useAuth } from "store/auth";

interface Props {
    nextPage: (num: number) => void;
}

export const BusinessSecondaryInfoSchema = () => {
    return object({
        website: string().required("Website is required"),
    });
};

export const BusinessSecondaryInfo = ({ nextPage }: Props) => {
    const previousValues: IBusinessUserInterface = JSON.parse(
        reactLocalStorage.get("@business_info_payload") || "{}"
    );
    const { user } = useAuth();
    const { initNotification } = usePageNotificationProvider();
    const navigate = useNavigate();
    const addQueryParam = (key: string, value: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    const { values, setValues, errors, touched, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                website: "",
                bookingLink: "",
                instagramLink: "",
                xLink: "",
                tiktokLink: "",
                facebookLink: "",
            },
            validationSchema: BusinessSecondaryInfoSchema,
            onSubmit: (e) => initRequest(e),
        });

    const handleChange = (e: any) => {
        let url = e.target.value;
        // Check if the URL doesn't start with http:// or https://
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }
        // Save the updated URL to state or wherever needed
        setValues((prev) => ({ ...prev, [e.target.name]: url }));
    };

    useEffect(() => {
        if (previousValues) {
            setValues((prev) => {
                return {
                    ...prev,
                    website: previousValues?.business?.website,
                    bookingLink: previousValues?.business?.bookingLink,
                    instagramLink: previousValues?.business?.instagramLink,
                    xLink: previousValues?.business?.xLink,
                    tiktokLink: previousValues?.business?.tiktokLink,
                    facebookLink: previousValues?.business?.facebookLink,
                };
            });
        }
    }, []);

    const initRequest = (payload?: any) => {
        const updatedValues: IBusinessUserInterface = {
            ...previousValues,
            business: {
                ...previousValues?.business,
                website: payload.website,
                bookingLink: payload.bookingLink,
                instagramLink: payload.instagramLink,
                xLink: payload.xLink,
                tiktokLink: payload.tiktokLink,
                facebookLink: payload.facebookLink,
            },
        };

        reactLocalStorage.set(
            "@business_info_payload",
            JSON.stringify(updatedValues)
        );
        addQueryParam("business", user?.id || "");
        nextPage && nextPage(3);
    };

    return (
        <>
            <AuthHeaderText
                title="Complete business profile"
                subTitle="Get into the details of your business, so your customers can find you."
                headingClassName="text-black text-3xl font-medium mb-5"
            />
            <form onSubmit={handleSubmit} className="">
                <div className="mt-3 d-flex flex-column w-100 gap-4">
                    <div className="my-5">
                        <PrimaryInput
                            defaultValue={"https://"}
                            type="text"
                            label="Website Url"
                            name="website"
                            placeholder="www.kaffykitchen.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.website}
                            error={Boolean(errors?.website && touched?.website)}
                            bottomText={errors?.website || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Booking or appointment links"
                            name="bookingLink"
                            placeholder="www.kaffykitchen.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bookingLink}
                            error={Boolean(
                                errors?.bookingLink && touched?.bookingLink
                            )}
                            bottomText={errors?.bookingLink || ""}
                        />
                    </div>

                    <br />
                    <AuthHeaderText
                        title="CONNECT YOUR SOCIALS"
                        subTitle="Enter links to your social media accounts so clients can see you everywhere"
                        headingClassName="text-black text-2xl font-semibold mb-1"
                    />

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Instagram link"
                            name="instagramLink"
                            placeholder="www.instagram.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.instagramLink}
                            error={Boolean(
                                errors?.instagramLink && touched?.instagramLink
                            )}
                            bottomText={errors?.instagramLink || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="X link (Formerly Twitter)"
                            name="xLink"
                            placeholder="www.twitter.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.xLink}
                            error={Boolean(errors?.xLink && touched?.xLink)}
                            bottomText={errors?.xLink || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Tiktok link"
                            name="tiktokLink"
                            placeholder="www.tiktok.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tiktokLink}
                            error={Boolean(
                                errors?.tiktokLink && touched?.tiktokLink
                            )}
                            bottomText={errors?.tiktokLink || ""}
                        />
                    </div>

                    <div className="my-5">
                        <PrimaryInput
                            type="text"
                            label="Facebook Link"
                            name="facebookLink"
                            placeholder="www.facebook.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.facebookLink}
                            error={Boolean(
                                errors?.facebookLink && touched?.facebookLink
                            )}
                            bottomText={errors?.facebookLink || ""}
                        />
                    </div>
                </div>
                <PrimaryButton
                    type="submit"
                    className="btn-lg primary-btn mt-2 align-self-baseline"
                >
                    Submit
                </PrimaryButton>
            </form>
        </>
    );
};
