import { PrimaryLoader } from "components";
import { useGeneralAppProvider } from "providers";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "store/auth";

export const AuthVerifyContainer = () => {
  const { user, isLoading: loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { primaryLoading } = useGeneralAppProvider();

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  // console.log(loading);

  if (!user) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ from: window.location.pathname }}
      />
    );
  }

  return (
    <>
      {loading && <PrimaryLoader height={"90vh"} />}
      <Outlet />
    </>
  );
};
