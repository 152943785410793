/* eslint-disable react-hooks/exhaustive-deps */
import {
    ChakraAlertDialog,
    CustomQuillTextarea,
    PrimaryButton,
    PrimaryInput,
    PrimaryTextarea,
} from "components";
import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { useModalProvider } from "providers/modalProvider";
import { useWindowWidth } from "utilities/windowWidth";
import { usePostEditSuggestionsMutation } from "store/business";
import { useLocation, useParams } from "react-router";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useFormik } from "formik";
import { object, string } from "yup";

interface SuggestEditModalProps {
    isOpen?: boolean;
    onClose?: () => void;
}

export const SuggestEditSchema = () => {
    return object({
        suggestion: string().required("Please enter your suggestion"),
    });
};

export const SuggestEditModal: FC<SuggestEditModalProps> = ({
    //   isOpen = false,
    onClose = () => {},
    ...rest
}) => {
    // const [value, setValue] = useState<string>("");
    const [reset, setReset] = useState(false);
    const windowWidth = useWindowWidth();
    const [param, setParam] = useState(
        localStorage.getItem("suggestingBusinessId")
    );
    const [postEditSuggestions, { upLoading }] =
        usePostEditSuggestionsMutation();
    const { initModal, getModalPayload } = useModalProvider();
    const { values, errors, touched, handleChange, handleBlur } = useFormik({
        initialValues: {
            suggestion: "",
        },
        validationSchema: SuggestEditSchema,
        onSubmit: (e) => initRequest(e),
    });
    const { initNotification } = usePageNotificationProvider();

    const initRequest = (e: any) => {
        e.preventDefault();
        // console.log("EDIT SUGGESTION");

        postEditSuggestions({
            businessID: Number(localStorage.getItem("suggestingBusinessId")),
            suggestion: values.suggestion.toString(),
        })
            .unwrap()
            .then((res: any) => {
                initNotification({
                    scheme: "success",
                    message: res.message,
                });
                // close modal
                onClose();
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message:
                        err?.data?.message ===
                            "Authorization header is required" ||
                        err.error === "Authorization header is required"
                            ? "Please log in"
                            : err?.data?.message || err.error,
                })
            );
    };

    useEffect(() => {
        setParam(localStorage.getItem("suggestingBusinessId"));
        // console.log(localStorage.getItem("suggestingBusinessId"));
    }, [param, localStorage.getItem("suggestingBusinessId"), values]);

    return (
        <>
            <ChakraAlertDialog
                useFooter={false}
                useCancelButton={false}
                onClose={onClose}
                useProceedButton={false}
                useHeader={true}
                headerProps={{
                    className:
                        windowWidth <= 768
                            ? "px-0 pt-0 text-[#303030] font-medium mt-3"
                            : "px-0 pt-0 text-[#303030] text-[28px] font-medium",
                }}
                useFloatingCancel={false}
                title={"Suggest an edit"}
                size={"3xl"}
                closeOnOverlayClick={true}
                modalContentProps={{ className: "rounded-md p-9" }}
                modalBodyProps={{ className: "p-0" }}
                {...rest}
            >
                <form
                    className="max-h-[500px] overflow-auto py-5"
                    onSubmit={initRequest}
                    style={{
                        maxHeight: windowWidth >= 768 ? "400px" : "600px",
                        // marginTop: windowWidth >= 768 ? "22px" : "22px",
                    }}
                >
                    {/* <Input
                        placeholder="What would you like to suggest?"
                        onChange={(val) => setValue(val.target.value)}
                        className={
                            "border border-[#C2C2C2] rounded-2xl min-h-44"
                        }
                        error={Boolean(!value)}
                        bottomText={"Please enter your suggestion" || ""}
                    /> */}

                    <PrimaryInput
                        type="text"
                        label="Suggest an edit for this business"
                        name="suggestion"
                        placeholder="What would you like to suggest?"
                        className="border border-[#C2C2C2] rounded-2xl ml-2 w-11/12 py-6"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.suggestion}
                        error={Boolean(
                            errors?.suggestion && touched?.suggestion
                        )}
                        bottomText={errors?.suggestion || ""}
                    />

                    <PrimaryButton
                        type="submit"
                        className="btn-md primary-btn mt-5 !font-normal"
                    >
                        Submit
                    </PrimaryButton>
                </form>
            </ChakraAlertDialog>
        </>
    );
};
