import React, { FC } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { IBusinessAverageRatings, IBusinessReview } from "store/review";
import Rating from "react-rating-fix";
import moment from "moment";
import { ProgressBar } from "components/custom";

const ModifiedRating = Rating as any;

interface AverageRatingsComponentProps {
  ratings: IBusinessAverageRatings;
  reviews: IBusinessReview[];
}

export const AverageRatingsComponent: FC<AverageRatingsComponentProps> = ({
  ratings,
  reviews,
}) => {
  // console.log(ratings, reviews);
  const getRatingPercentage = (rating: number) => {
    return (rating / 5) * 100;
  };

  const lastUpdated = reviews?.reduce((previous, current) => {
    return new Date(previous.createdAt) > new Date(current.createdAt)
      ? previous
      : current;
  }, {} as IBusinessReview);

  const getRemark = (rating: number) => {
    if (rating >= 0 && rating < 1) {
      return "Very bad";
    } else if (rating >= 1 && rating < 2) {
      return "Fair";
    } else if (rating >= 2 && rating < 3) {
      return "Average";
    } else if (rating >= 3 && rating < 4) {
      return "Very Good";
    } else if (rating >= 4 && rating <= 5) {
      return "Perfect";
    } else {
      return "N/A";
    }
  };

  return (
    <div className="border border-[#DADADA] py-5 px-3 rounded-2xl">
      <div className="flex items-center justify-between lg:flex-row flex-col p-2 pb-4 border-b border-[#DADADA]">
        <div className="lg:w-1/3 w-full lg:px-10 px-4 pb-1 lg:mb-0 mb-6">
          <h5 className="text-[#606060] font-semibold text-base">
            Total Reviews
          </h5>
          <h6 className="text-[#4D4D4D] font-medium text-[28px] my-3">
            {ratings?.reviewsCount ?? 0}
          </h6>
          <p className="text-[#919191] font-normal text-xs">
            Last updated on{" "}
            {moment(lastUpdated?.createdAt).format("Do MMMM, YYYY")}
          </p>
        </div>

        <div className="lg:border-r lg:border-l border-[#DADADA] lg:w-1/3 w-full lg:px-10 px-4 pb-1 lg:mb-0 mb-6">
          <h5 className="text-[#606060] font-semibold text-base">
            Average Rating
          </h5>
          <div className="flex items-center gap-5 my-3">
            <h6 className="text-[#4D4D4D] font-medium text-[28px]">
              {ratings?.averageRating.toFixed(1) ?? 0}
            </h6>

            <ModifiedRating
              // placeholderRating={3.5}
              readonly
              initialRating={ratings?.averageRating}
              emptySymbol={<HiOutlineStar className="h-5 w-5 text-[#FFC554]" />}
              fullSymbol={<HiStar className="h-5 w-5 text-[#FFA800]" />}
              fractions={2}
            />
            {/* <Rating
                      value={rating}
                      onChange={setRating}
                      // itemStyles={{
                      //   ThinRoundedStar,
                      //   activeFillColor: "#FFA800",
                      //   activeBorderColor: "#FFC554",
                      //   inactiveFillColor: "#ffedd5",
                      // }}
                    /> */}
          </div>
          <p className="text-[#919191] font-normal text-xs">
            Last updated on{" "}
            {moment(lastUpdated?.createdAt).format("Do MMMM, YYYY")}
          </p>
        </div>

        <div className="lg:w-1/3 w-full lg:ps-8 ps-4 pe-2 pb-1 space-y-2">
          {[
            { rating: "5 Stars", percentage: ratings?.percentage5Star },
            { rating: "4 Stars", percentage: ratings?.percentage4Star },
            { rating: "3 Stars", percentage: ratings?.percentage3Star },
            { rating: "2 Stars", percentage: ratings?.percentage2Star },
            { rating: "1 Star", percentage: ratings?.percentage1Star },
          ].map((item, index) => (
            <div className="flex items-center gap-2">
              <p className="text-[10px] w-[20%] text-[#919191] font-medium whitespace-nowrap">
                {item.rating}
              </p>
              <div className="w-[80%]">
                <ProgressBar value={item.percentage} height="6px" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center flex-wrap px-4 pt-6 pb-1 gap-4 justify-between">
        {[
          {
            rating: ratings?.averageCustomerService.toFixed(1) ?? 0,
            value: getRemark(ratings?.averageCustomerService),
            category: "Customer service",
          },
          {
            rating: ratings?.averageDeliveryRating.toFixed(1) ?? 0,
            value: getRemark(ratings?.averageDeliveryRating),
            category: "Delivery",
          },
          {
            rating: ratings?.averageQuality.toFixed(1) ?? 0,
            value: getRemark(ratings?.averageQuality),
            category: "Product quality",
          },
          {
            rating: ratings?.averageValueForMoney.toFixed(1) ?? 0,
            value: getRemark(ratings?.averageValueForMoney),
            category: "Value for money",
          },
        ].map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="w-[120px] h-[120px] text-center ">
              <CircularProgressbarWithChildren
                value={getRatingPercentage(Number(item.rating))}
                styles={buildStyles({
                  pathColor: "#FFC554",
                })}
              >
                <b className="text-[#FFC554] font-medium text-[28px]">
                  {item.rating}
                </b>
                <small className="text-[#919191] font-medium">
                  {item.value}
                </small>
              </CircularProgressbarWithChildren>
            </div>
            <h5 className="text-[#919191] lg:text-lg text-base font-medium mt-3">
              {item.category}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
};
