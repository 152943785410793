import style from "../../styles/searchComponent.module.scss";
import Placeholder from "assets/svg/Placeholder.svg";
import RatingStars from "assets/svg/RatingStars.svg";
import { FC, Key, useState } from "react";
import { BusinessPage } from "../businesses";
import { FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Rating from "react-rating-fix";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

const ModifiedRating = Rating as any;
interface Business {
    business: any;
}

interface Category {
    businesses: null | any;
    createdAt: string;
    deletedAt: string;
    id: number;
    name: string;
    updatedAt: string;
}

export const SearchBusinessCard: FC<Business> = ({ business }) => {
    const truncateText = (text: string) => {
        const truncatedText = text.slice(0, 120);
        const isTruncated = text.length > truncatedText.length;
        const visibleText = truncatedText + (isTruncated ? "..." : "");
        return visibleText;
    };

    // console.log(business);

    return (
        <>
            <Link
                to={`/businesses/${business?.id}`}
                className={`${style.chickenRepublic} sm:items-center items-start`}
            >
                <div className={style.imageCR}>
                    <img
                        alt=""
                        className="max-h-44 aspect-square object-contain"
                        src={
                            // if business.businessLogo has .png or jpg or jpeg or gif then display it
                            business.businessLogo?.includes(".png") ||
                            business.businessLogo?.includes(".jpg") ||
                            business.businessLogo?.includes(".jpeg") ||
                            business.businessLogo?.includes(".gif")
                                ? business.businessLogo
                                : Placeholder
                        }
                    />
                </div>

                <div className={style.searchDetails}>
                    <a href="/business-page">
                        <h3 className="mb-2">{business.businessName}</h3>
                    </a>

                    <div className="flex mt-2 mb-2">
                        <ModifiedRating
                            // placeholderRating={3.5}
                            readonly
                            initialRating={business?.averageRating?.toFixed(1)}
                            emptySymbol={
                                <HiOutlineStar className="h-5 w-5 text-[#FFC554]" />
                            }
                            fullSymbol={
                                <HiStar className="h-5 w-5 text-[#FFA800]" />
                            }
                            fractions={2}
                        />

                        <div className={style.displayPhone}>
                            {/* <p>{business.averageRating}</p> */}
                            {/* <FaStar
                                style={{
                                    width: "22.82px",
                                    height: "20px",
                                    color: "gold",
                                }}
                            /> */}
                        </div>
                        <p>({business?.reviewCount})</p>
                    </div>

                    <div className="mt-4 mb-4">
                        {business?.businessCategory?.map(
                            (category: Category, index: Key | number) => (
                                <span key={index}>{category.name}</span>
                            )
                        )}
                    </div>

                    <p className={style.ellipsis}>
                        {truncateText(business.about)}
                    </p>
                </div>
            </Link>
        </>
    );
};
