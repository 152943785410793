import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { IResponse } from "../auth/interface";
import { PaginatedPayload } from "store/interface";
import {
    GetBusinessReviewsPayload,
    IBusinessReviewRes,
    IGetForumResponse,
    IGetForumStatsResponse,
    IGetReviewsAverageSummary,
    IGetReviewsRecent,
    IGetWriteForumParam,
    IPostWriteForumQuestionPayload,
    ReviewFormData,
} from "./interface";
import { IReviewRes } from "pages/auth/business/business-Interface";
import { convertObjectToURLParams } from "utilities/general";

export const reviewApi = createApi({
    reducerPath: "reviewApi",
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/api/` }),

    endpoints: (builder) => ({
        postBusinessReview: builder.mutation<IResponse, ReviewFormData>({
            query: (credentials) => ({
                url: `business-reviews/${credentials.business}/reviews`,
                method: "POST",
                body: credentials,
            }),
        }),

        editBusinessReview: builder.mutation<
            IResponse,
            { review: ReviewFormData; reviewid: string | number }
        >({
            query: ({ review, reviewid }) => ({
                url: `business-reviews/${reviewid}`,
                method: "PUT",
                body: review,
            }),
        }),

        deleteBusinessReview: builder.mutation<IResponse, string>({
            query: (id) => ({
                url: `business-reviews/${id}`,
                method: "DELETE",
            }),
        }),

        getBusinessReviews: builder.query<
            IBusinessReviewRes,
            GetBusinessReviewsPayload
        >({
            query: ({ id, query }) => ({
                url: `business-reviews?${query?.type === "user" ? "userId" : "businessId"
                    }=${id}&${convertObjectToURLParams(query)}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        getBusinessReviewsRecent: builder.query<IGetReviewsRecent, any>({
            query: ({ id, query }) => ({
                url: `business-reviews/recent?${query?.type === "user" ? "userId" : "businessId"
                    }=${id}&${convertObjectToURLParams(query)}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        getBusinessReviewsAverageSummary: builder.query<IGetReviewsAverageSummary, { id: string }>({
            query: ({ id }) => ({
                url: `/business-reviews/average-summary/${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        getReviews: builder.query<
            IReviewRes,
            { type: "product" | "user"; id: string }
        >({
            query: ({ type, id }) => ({
                url: `reviews/${type}/${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        likeBusinessReview: builder.mutation<IResponse, number>({
            query: (reviewId) => ({
                url: `business-reviews/like-business-review/${reviewId}`,
                method: "POST",
                body: reviewId,
            }),
        }),

        unlikeBusinessReview: builder.mutation<IResponse, number>({
            query: (reviewId) => ({
                url: `business-reviews/unlike-business-review/${reviewId}`,
                method: "POST",
                body: reviewId,
            }),
        }),

        uploadBusinessReviewPhoto: builder.mutation<IResponse, FormData>({
            query: (files) => ({
                url: `business-reviews/upload`,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                body: files,
            }),
        }),

        //! FORUM
        //! FORUM
        //! FORUM
        postWriteForumQuestion: builder.mutation<
            IResponse,
            IPostWriteForumQuestionPayload
        >({
            query: (payload) => ({
                url: `forum`,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                body: payload,
            }),
        }),

        editWriteForumQuestion: builder.mutation<
            IResponse,
            { payload: any, id: string }
        >({
            query: ({ payload, id }) => ({
                url: `forum/${id}`,
                method: "PUT",
                headers: { "Content-Type": "multipart/form-data" },
                body: payload,
            }),
        }),

        getForums: builder.query<IGetForumResponse, IGetWriteForumParam>({
            query: ({ id, MustBeParent, businessID, callerID, parentID, userID, page, limit, }) => ({
                url: `forum${getParamFromKeys({
                    id,
                    MustBeParent,
                    businessID,
                    callerID,
                    parentID,
                    userID,
                    page,
                    limit,
                })}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        deleteForum: builder.mutation<IResponse, string>({
            query: (id) => ({
                url: `forum/${id}`,
                method: "DELETE",
            }),
        }),

        getQuestionStats: builder.query<
            IGetForumStatsResponse,
            { id: string | number }
        >({
            query: ({ id }) => ({
                url: `forum/${id}/stats`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),

        postWriteForumAnswer: builder.mutation<
            IResponse,
            IPostWriteForumQuestionPayload
        >({
            query: (payload) => ({
                url: `forum/answer`,
                method: "POST",
                body: payload,
            }),
        }),

        postReactForumQuestionReply: builder.mutation<
            IResponse,
            { id: number; state: "like" | "dislike" | "none" }
        >({
            query: (payload) => ({
                url: `forum/${payload.id}/reaction?state=${payload.state}`,
                method: "POST",
                body: payload,
            }),
        }),

        postReactReview: builder.mutation<
            IResponse,
            { id: number; state: "like" | "dislike" | "none" }
        >({
            query: (payload) => ({
                url: `business-reviews/${payload.id}/reaction?state=${payload.state}`,
                method: "POST",
                body: payload,
            }),
        }),

        //! PRODUCTS
        getAllProducts: builder.query<IReviewRes, string>({
            query: (id) => ({
                url: `all-products?businessID=${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
    }), // end --
});

export const {
    usePostBusinessReviewMutation,
    useEditBusinessReviewMutation,
    useDeleteBusinessReviewMutation,
    useGetBusinessReviewsQuery,
    useGetBusinessReviewsRecentQuery,
    useGetBusinessReviewsAverageSummaryQuery,
    useGetReviewsQuery,
    useGetForumsQuery,
    useDeleteForumMutation,
    useGetAllProductsQuery,
    usePostReactReviewMutation,
    useGetQuestionStatsQuery,
    useLikeBusinessReviewMutation,
    useUnlikeBusinessReviewMutation,
    useEditWriteForumQuestionMutation,
    usePostWriteForumQuestionMutation,
    usePostWriteForumAnswerMutation,
    usePostReactForumQuestionReplyMutation,
    useUploadBusinessReviewPhotoMutation,
} = reviewApi;

const getParamFromKeys = (param: any) => {
    let params = "?";
    if (!param) return "";
    Object.keys(param).forEach((key) => {
        if (param[key]) params += `${key}=${param[key]}&`;
    });
    return params;
};
