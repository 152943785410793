import React, { FC, useEffect, useState } from "react";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import {
    BiChevronDown,
    BiChevronLeft,
    BiComment,
    BiSearch,
    BiSolidComment,
    BiStar,
} from "react-icons/bi";
import {
    BsFillSendFill,
    BsGlobe,
    BsHandThumbsDown,
    BsHandThumbsUp,
} from "react-icons/bs";
import style from "../../styles/dashboard.module.scss";
import { FiEdit, FiImage, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { GoComment, GoCommentDiscussion, GoTrash } from "react-icons/go";
import userImg from "../../../assets/svg/userImg.svg";
import { MdCancel, MdStar } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router";
import { useAuth } from "store/auth";
import { useGetMyBusinessQuery } from "store/business";
import {
    IBusinessReview,
    useDeleteBusinessReviewMutation,
    useGetBusinessReviewsQuery,
    useLikeBusinessReviewMutation,
    usePostReactReviewMutation,
    useUnlikeBusinessReviewMutation,
} from "store/review";
import { formatDate } from "utilities/dateFormatter";
import {
    Comment,
    useGetCommentRepliesQuery,
    useGetCommentsByReviewIdQuery,
    useGetCommentStatsQuery,
    useLikeCommentQuery,
    usePostCommentReplyMutation,
    useUnlikeCommentQuery,
} from "store/comments";
import { PrimaryInput } from "components/inputs";
import Rating from "react-rating-fix";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { getNameInitials, resolveApiError } from "utilities/index";
import { FaComment, FaRegComment } from "react-icons/fa6";
import { CustomQuillTextarea } from "components/custom";
import { useModalProvider } from "providers/index";
import { PrimaryLoader } from "components/loaders";
import { skipToken } from "@reduxjs/toolkit/query";

const ModifiedRating = Rating as any;

interface ReadReviewProps {
    canReply?: boolean;
}

export const ReadReview: FC<ReadReviewProps> = ({ canReply }) => {
    const navigate = useNavigate();
    const [rating, setRating] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const { initNotification } = usePageNotificationProvider();
    const [ratingFilter, setRatingFilter] = useState(false);
    const [highlight, setHighlight] = useState(false);
    const { id } = useParams();
    const { user } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");

    const { data, refetch, isLoading } = useGetBusinessReviewsQuery(
        user?.id
            ? {
                  id: id || businessInfo?.data[0]?.id,
                  query: {
                      rating,
                      type: "business",
                      callerId: user?.id,
                      search: searchValue,
                  },
              }
            : {
                  id: id || businessInfo?.data[0]?.id,
                  query: {
                      rating,
                      type: "business",
                      search: searchValue,
                  },
              }
    );

    const [reviews, setReviews] = useState<IBusinessReview[] | undefined>(
        data?.data?.reviews
    );
    const [deleteBusinessReview, { isLoading: deleting }] =
        useDeleteBusinessReviewMutation();

    const handleDeleteReview = (reviewId: string) => {
        deleteBusinessReview(reviewId)
            .unwrap()
            .then((res: any) => {
                // console.log(res);
                refetch();
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setReviews(data?.data as unknown as IBusinessReview[]);

        // console.log(data?.data);

        // data?.data === undefined &&
        //     initNotification({
        //         message: "Please log in to see business reviews",
        //         scheme: "info",
        //     });
    }, [businessInfo?.data, data, reviews]);

    const location = useLocation();

    // console.log(rating);

    // useEffect(() => {
    //   // Scroll to the element with the ID from the fragment identifier
    //   if (location.hash) {
    //     const element = document.querySelector("location.hash");
    //     if (element) {
    //       element.scrollIntoView({ behavior: "smooth" });
    //     }
    //   }
    // }, [location.hash]);

    if (isLoading) {
        return <PrimaryLoader height={"90vh"} />;
    }

    return (
        <>
            <div className={`${style.read_review} container mx-auto `}>
                <span
                    className={`${style.back_btn} flex items-center mb-3`}
                    onClick={() => navigate(-1)}
                >
                    <BiChevronLeft /> back
                </span>
                <div
                    className={`${style.review_cont} flex justify-between items-stretch md:flex-row flex-col md:items-end py-3 rounded  mb-4`}
                >
                    <div className="flex md:flex-col gap-4 flex-row mt-4 md:items-start items-center justify-between ">
                        <h1 className="text-primary-500 font-medium !text-base lg:!text-[28px] py-2">
                            All Reviews ({reviews?.length || 0})
                        </h1>
                        <div
                            className={`${style.filter} text-gray-800 flex gap-3 items-center px-4 py-1 rounded`}
                            // onMouseOver={() => setHover(true)}
                        >
                            <BsGlobe />
                            <div className="flex flex-col gap-1">
                                <span
                                    role="button"
                                    onClick={() =>
                                        setRatingFilter(!ratingFilter)
                                    }
                                >
                                    Filter by Ratings
                                </span>
                                {ratingFilter ? (
                                    <div
                                        className={`flex gap-3 ${style.ratingStars}`}
                                    >
                                        <ModifiedRating
                                            initialRating={rating}
                                            emptySymbol={
                                                <HiOutlineStar className="h-5 w-5 me-1 text-[#FFC554]" />
                                            }
                                            fullSymbol={
                                                <HiStar className="h-5 w-5 me-1 text-[#FFA800]" />
                                            }
                                            fractions={1}
                                            start={0}
                                            stop={5}
                                            onChange={setRating}
                                            // step={5}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {Number(rating) > 0 && (
                                <>
                                    <span className="font-medium">
                                        {rating || 0}
                                    </span>
                                    <span
                                        role="button"
                                        onClick={() => setRating("")}
                                    >
                                        <MdCancel />
                                    </span>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="mt-4 md:max-w-[260px] w-full">
                        {/* <div
                className={`${style.sort_by} text-gray-800 px-4  rounded  flex`}
              >
                Sort by
                <BiChevronDown />
              </div> */}

                        <div className="relative ml-0">
                            <BiSearch className="absolute top-1/2 -translate-y-1/2 ml-3 text-[#6C6C6C]" />
                            <PrimaryInput
                                type="text"
                                name="search"
                                placeholder="Search for reviews"
                                className="!pl-8 h-full rounded-lg"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                value={searchValue}
                                // onBlur={handleBlur}
                            />
                        </div>
                    </div>
                </div>

                {!reviews?.length && (
                    <div className="text-center mt-4">
                        <h2 className="text-2xl font-bold text-gray-800">
                            No Reviews
                        </h2>
                        {/* <p className="text-gray-600">
              Be the first to share your experience
            </p> */}
                        <br />
                    </div>
                )}

                {!!reviews?.length && (
                    <>
                        {[...reviews]
                            ?.sort(
                                (a, b) =>
                                    new Date(b.createdAt).getTime() -
                                    new Date(a.createdAt).getTime()
                            )
                            .map((review, index) => (
                                <ReviewCard
                                    key={index}
                                    businessId={businessInfo?.data[0]?.id || id}
                                    userImg={userImg}
                                    review={review}
                                    searchValue={searchValue}
                                    handleDeleteReview={handleDeleteReview}
                                    canReply={canReply}
                                    refetch={refetch}
                                />
                            ))}
                        {/* <div className="mt-4 text-center">
              <span>Showing 1 of 20</span>
              <div className="flex justify-center space-x-2 mt-2">
                <button className="bg-gray-200 px-2 py-1 rounded">1</button>
                <button className="bg-gray-200 px-2 py-1 rounded">2</button>
                <button className="bg-gray-200 px-2 py-1 rounded">3</button>
                <button className="bg-gray-200 px-2 py-1 rounded">4</button>
                <button className="bg-gray-200 px-2 py-1 rounded">5</button>
                <button className="bg-gray-200 px-2 py-1 rounded">6</button>
              </div>
            </div> */}
                    </>
                )}
            </div>
        </>
    );
};

type ReviewCardProps = {
    businessId: string;
    userImg: string;
    review: IBusinessReview;
    searchValue: string;
    handleDeleteReview: (id: string) => void;
    canReply?: boolean;
    refetch: () => void;
};

function ReviewCard({
    businessId,
    userImg,
    review,
    searchValue,
    handleDeleteReview,
    canReply = true,
    refetch,
}: ReviewCardProps) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [reply, setReply] = useState<string>("");
    const [reset, setReset] = useState(false);
    const [showReviewReplies, setShowReviewReplies] = useState(false);
    const { initNotification } = usePageNotificationProvider();
    const { initModal } = useModalProvider();
    const [postCommentReply, { isLoading }] = usePostCommentReplyMutation();
    const [postReactReview] = usePostReactReviewMutation();
    const { data: reviewComments, refetch: refetchReviewComments } =
        useGetCommentsByReviewIdQuery({
            id: review?.id,
        });

    const commentPayload = {
        // parentId: businessId,
        reviewId: review?.id,
        text: reply,
    };

    const handleToggleReviewReplies = (id: number) => {
        if (id === review?.id) {
            setShowReviewReplies(!showReviewReplies);
        }
    };

    const submitReply = (e: any) => {
        e.preventDefault();
        // console.log(postCommentReply);
        postCommentReply(commentPayload)
            .unwrap()
            .then((res: any) => {
                setReply("");
                setReset(true);
                setTimeout(() => {
                    setReset(false);
                }, 500);
                initNotification({
                    scheme: "success",
                    message: res.message,
                });
                refetchReviewComments();
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message:
                        err?.data?.message ===
                            "Authorization header is required" ||
                        err.error === "Authorization header is required"
                            ? "Please log in"
                            : err?.data?.message || err.error,
                })
            );
    };

    const handleToggleLikeBusinessReview = (
        type: "like" | "dislike" | "none"
    ) => {
        postReactReview({ id: review.id, state: type })
            .unwrap()
            .then((res: any) => {
                refetch();
                return;
            })
            .catch((err: any) =>
                initNotification({
                    scheme: "error",
                    message:
                        err?.data?.message ===
                            "Authorization header is required" ||
                        err.error === "Authorization header is required"
                            ? "Please log in to like"
                            : err?.data?.message || err.error,
                })
            );
    };

    const highlightHTML = (html: string, searchText: string) => {
        if (!searchText.trim()) return html;

        const regex = new RegExp(`(${searchText})`, "gi");

        return html.replace(
            regex,
            (match: any) =>
                `<span style="background-color: #2c5aa3;color: white;padding: 1px 2px;border-radius: 4px;">${match}</span>`
        );
    };

    useEffect(() => {
        localStorage.removeItem("review-state");
        // console.log(review);
    }, []);

    return (
        <div
            key={review?.id}
            id={review?.id + review?.business?.businessName}
            className={`${
                style.review_card
            } p-4 mt-4 scroll-mt-[145px] border border-[#DADADA] ${
                showReviewReplies ? "bg-white" : "bg-transparent"
            }`}
        >
            <div className="flex justify-between gap-6 sm:flex-row flex-col">
                <div className="flex items-start space-x-3">
                    <img src={userImg} alt="Trade Tracka" />
                    <div>
                        <h2 className="text-2xl font-bold mb-1">
                            {review?.user?.firstName || "No Name"}{" "}
                            {review?.user?.lastName}
                        </h2>

                        <div className="flex items-start flex-col space-x-3">
                            <span className="text-[10px] text-[#919191]">
                                Average ratings
                            </span>
                            <div className="text-gray-600 flex items-center justify-between gap-2 !ml-0">
                                <ModifiedRating
                                    // placeholderRating={3.5}
                                    readonly
                                    initialRating={review?.rating}
                                    emptySymbol={
                                        <HiOutlineStar className="h-5 w-5 text-[#FFC554]" />
                                    }
                                    fullSymbol={
                                        <HiStar className="h-5 w-5 text-[#FFA800]" />
                                    }
                                    fractions={2}
                                    start={0}
                                    stop={5}
                                    step={5}
                                />
                                <p className="text-[#919191] text-base font-semibold">
                                    {review?.rating}
                                </p>
                            </div>
                        </div>
                        <div>
                            <span className="text-[10px] text-[#919191]">
                                Date of experience
                            </span>
                            <p className="!text-sm !text-[#919191]">
                                {
                                    formatDate(
                                        review?.dateOfExperience ||
                                            review?.createdAt
                                    ).slashNumberDateFormat
                                }
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className={`${style.rating_group} sm:mb-0 mb-6 self-center opacity-100 visible scale-100 md:opacity-0 md:invisible md:scale-0 `}
                >
                    <div className="flex justify-between items-center flex-wrap gap-y-4">
                        {[
                            {
                                label: "Value for money",
                                value: review?.valueForMoney,
                            },
                            {
                                label: "Delivery",
                                value: review?.deliveryRating,
                            },
                            {
                                label: "Customer service",
                                value: review?.customerService,
                            },
                            {
                                label: "Product quality",
                                value: review?.quality,
                            },
                        ].map((item, index) => (
                            <div className="w-1/2 text-center">
                                <span className="flex justify-center items-center gap-2">
                                    <ModifiedRating
                                        // placeholderRating={3.5}
                                        readonly
                                        initialRating={item.value}
                                        emptySymbol={
                                            <HiOutlineStar className="h-5 w-5 text-[#FFC554]" />
                                        }
                                        fullSymbol={
                                            <HiStar className="h-5 w-5 text-[#FFA800]" />
                                        }
                                        fractions={2}
                                        start={0}
                                        stop={5}
                                        step={5}
                                    />
                                    <p className="text-[#919191] !text-lg font-semibold">
                                        {item.value}
                                    </p>
                                </span>
                                <p>{item.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className="mt-1 mb-7 text-[#606060]"
                dangerouslySetInnerHTML={{
                    __html: highlightHTML(review?.comment, searchValue),
                }}
            />

            <div className="flex items-center space-x-4 mt-4">
                <button
                    type="button"
                    className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    onClick={() => handleToggleLikeBusinessReview("like")}
                >
                    <sup>
                        <FiThumbsUp
                            className={`h-5 w-5 ${
                                review.currentUserReaction === "like"
                                    ? "text-primary-500 fill-primary-500"
                                    : ""
                            }`}
                        />
                    </sup>
                    <sub>{review?.likeCount}</sub>
                </button>
                <button
                    type="button"
                    className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    onClick={() => handleToggleLikeBusinessReview("dislike")}
                >
                    <sup>
                        <FiThumbsDown
                            className={`h-5 w-5 ${
                                review.currentUserReaction === "dislike"
                                    ? "text-primary-500 fill-primary-500"
                                    : ""
                            }`}
                        />
                    </sup>
                    <sub>{review?.dislikeCount}</sub>
                </button>
                <button
                    type="button"
                    className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    onClick={() => handleToggleReviewReplies(review?.id)}
                >
                    <sup>
                        {showReviewReplies ? (
                            <FaComment className="h-5 w-5 text-primary-500" />
                        ) : (
                            <FaRegComment className="h-5 w-5" />
                        )}
                    </sup>
                    <sub>{reviewComments?.data?.length}</sub>
                </button>
                <button
                    type="button"
                    className="flex items-center justify-between border-0 overflow-visible !font-normal"
                    onClick={() =>
                        initModal((prev) => ({
                            ...prev,
                            businessPhotos: {
                                payload: {
                                    businessReviewImages:
                                        review?.businessReviewImages,
                                },
                            },
                        }))
                    }
                >
                    <sup>
                        <FiImage className="text-primary-500 h-5 w-5" />
                    </sup>
                    <sub>{review?.businessReviewImages.length}</sub>
                </button>
                <p className="text-sm text-gray-600">
                    {formatDate(review?.createdAt).commaDateFormat}
                </p>

                {review.user.id === user?.id && (
                    <div className="md:ml-auto md:w-3/5 text-4xl flex items-center justify-end gap-3 md:gap-6">
                        <button
                            onClick={() => {
                                localStorage.setItem(
                                    "review-state",
                                    JSON.stringify({
                                        state: "edit",
                                        review: review,
                                    })
                                );
                                navigate(
                                    `/review?business=${review.businessID}`
                                );
                            }}
                            className="cursor-pointer text-primary-600 border-0 py-2 px-3 flex gap-2 items-center"
                        >
                            <FiEdit />
                            <h2 className="text-primary-500 md:inline hidden">
                                Edit
                            </h2>
                        </button>
                        <button
                            onClick={() =>
                                window.confirm(
                                    "Are you sure you want to delete your review?"
                                ) && handleDeleteReview(String(review?.id))
                            }
                            className="cursor-pointer text-red-600 border-0 py-2 px-3 flex gap-2 items-center"
                        >
                            <GoTrash />
                            <h2 className="text-red-500 md:inline hidden">
                                Delete
                            </h2>
                        </button>
                    </div>
                )}
            </div>

            {showReviewReplies ? (
                <div
                    className={`${style.review_card} bg-white p-4 mt-7 border border-[#DADADA]`}
                >
                    <div className="flex justify-between items-center mb-5">
                        <h4 className="text-2xl text-[#4D4D4D] font-medium">
                            Comments
                        </h4>
                        <button
                            onClick={() => setShowReviewReplies(false)}
                            className="text-base text-primary-500 flex border-none items-center gap-1"
                        >
                            <IoClose /> Close
                        </button>
                    </div>

                    <form onSubmit={submitReply} className={style.review_input}>
                        {/* <textarea
                          value={reply}
                          onChange={(e) => setReply(e.target.value)}
                          className="w-full p-2 bg-transparent resize-none rounded comment-input"
                          placeholder="Write a reply..."
                          rows={1}
                        /> */}
                        <CustomQuillTextarea
                            placeholder="Write a reply..."
                            onChange={(val) => setReply(val)}
                            reset={reset}
                            // minHeight={"80px"}
                            toolbar={null}
                        />
                        <button className="border-0 overflow-visible">
                            <BsFillSendFill className="h-8 w-8 text-[#6e6e6e] hover:text-primary-500 rotate-[30deg]" />
                        </button>
                    </form>

                    {reviewComments?.data?.length ? (
                        reviewComments?.data?.map((comment, index) => (
                            <CommentCard comment={comment} />
                        ))
                    ) : (
                        <div className="flex justify-center text-gray-600 text-2xl mt-8 font-medium">
                            <h4>No Comments yet</h4>
                        </div>
                    )}
                </div>
            ) : null}

            {/* {canReply ? (
        <form onSubmit={submitReply} className={style.review_input}>
          <input
            type="text"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            className="w-full p-2  rounded"
            placeholder="Write a reply..."
          />
          <button className="border-0">
            <BsFillSendFill />
          </button>
        </form>
      ) : null} */}
        </div>
    );
}

interface CommentCardProps {
    comment: Comment;
}

function CommentCard({ comment }: CommentCardProps) {
    const { data: commentReplies, refetch: refetchCommentReplies } =
        useGetCommentRepliesQuery({
            id: comment?.id,
        });
    const { data: commentStats, refetch: refetchCommentStats } =
        useGetCommentStatsQuery(comment?.id);

    const {
        data: unlikes,
        refetch: refetchCommentUnlikes,
        // isError,
        // error,
    } = useUnlikeCommentQuery(comment?.id, {
        skip: !comment?.id, // Skip the query if there's no comment ID
    });

    const {
        data: likes,
        refetch: refetchCommentLikes,
        isError,
        error,
    } = useLikeCommentQuery(comment?.id, {
        skip: !comment?.id, // Skip the query if there's no comment ID
    });

    const handleToggleLike = (id: number) => {
        if (id) {
            refetchCommentLikes();
            refetchCommentStats();
        }
    };

    const handleToggleUnlike = (id: number) => {
        if (id) {
            refetchCommentUnlikes();
            refetchCommentStats();
        }
    };
    const { initNotification } = usePageNotificationProvider();
    // useEffect(() => {
    //   initNotification({
    //     scheme: "error",
    //     message: resolveApiError(error),
    //   });
    // }, [error]);

    // console.log(likes, unlikes, isError, error);

    return (
        <div
            key={comment?.id}
            className={`${style.review_card} bg-white p-4 mt-6 border border-[#DADADA]`}
        >
            <div className="flex items-center gap-2">
                {/* {storedUser?.photoURL ? (
              <div className="w-6 h-6 flex flex-col text-center items-center justify-center rounded-full relative overflow-hidden">
                <img
                  src={storedUser?.photoURL}
                  className="w-full h-full object-cover"
                  alt="profile"
                />
              </div>
            ) : ( */}
                <div className="text-xs font-medium bg-primary-500 text-white rounded-full flex items-center justify-center h-6 w-6">
                    {comment?.isBusinessOwner
                        ? getNameInitials(comment?.businessName)
                        : getNameInitials(comment?.user?.firstName)}
                </div>
                {/* )} */}
                <h5 className="text-[#606060] text-base font-semibold">
                    {comment?.isBusinessOwner
                        ? comment?.businessName
                        : comment?.user?.firstName}
                </h5>
                <span className="inline-block h-2 w-2 rounded-full bg-[#C2C2C2]"></span>
                <h6 className="text-base text-[#C2C2C2] font-semibold">
                    {formatDate(comment?.createdAt).commaDateFormat}
                </h6>
            </div>
            <div
                className="my-5 text-base text-[#282828]"
                dangerouslySetInnerHTML={{ __html: comment?.text }}
            />
            <div className="flex items-center space-x-4 mt-4">
                <button
                    type="button"
                    className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    onClick={() => handleToggleLike(comment?.id)}
                >
                    <sup>
                        <BsHandThumbsUp className="h-5 w-5" />
                    </sup>
                    <sub>{commentStats?.data.likes}</sub>
                </button>
                <button
                    type="button"
                    className="flex items-center space-x-1 text-gray-600 border-0 overflow-visible !font-normal"
                    onClick={() => handleToggleUnlike(comment?.id)}
                >
                    <sup>
                        <BsHandThumbsDown className="h-5 w-5" />
                    </sup>
                    <sub>{commentStats?.data.dislikes}</sub>
                </button>
            </div>
        </div>
    );
}
