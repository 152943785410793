import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utilities/axiosQuery/axiosBaseQuery";
import { baseUrl } from "../../utilities/requests";
import { IResponse } from "../auth/interface";
import {
    AllBusinessesQueryPayload,
    IGetBusinessNotificationRes,
    IGetEditSuggestionRes,
    IGetMyBusinessRes,
    IPostEditSuggestionPayload,
} from "./interface";
import { convertObjectToURLParams } from "utilities";
import {
    BusinessInfoPayload,
    IBusinessInterfaceEdit,
    IBusinessUserInterface,
    IReviewRes,
} from "pages/auth/business/business-Interface";

export const businessApi: any = createApi({
    reducerPath: "businessApi",
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    endpoints: (builder) => ({
        getAllBusinesses: builder.query<any, AllBusinessesQueryPayload>({
            query: (payload) => ({
                url: `api/business/?${convertObjectToURLParams(payload)}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getAllOpenBusinesses: builder.query<any, AllBusinessesQueryPayload>({
            query: (payload) => ({
                url: `api/business/open?${convertObjectToURLParams(payload)}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getBusinessById: builder.query<any, string | number>({
            query: (id) => ({
                url: `api/business/${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getOpenBusinessById: builder.query<any, string | number>({
            query: (id) => ({
                url: `api/business/open/${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getMyBusiness: builder.query<IGetMyBusinessRes, string | number>({
            query: (id) => ({
                url: `api/business?personal=true&user_id=${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getEditSuggestions: builder.query<
            IGetEditSuggestionRes,
            string | number
        >({
            query: (id) => ({
                url: `api/business-edit-suggestions?businessID=${id}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        postEditSuggestions: builder.mutation<any, IPostEditSuggestionPayload>({
            query: (payload) => ({
                url: `api/business-edit-suggestions`,
                method: "POST",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
                body: payload,
            }),
        }),
        getBusinessByName: builder.query<any, string | undefined>({
            query: (name) => ({
                url: `api/business-by-name?name=${name}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getBusinessNotification: builder.query<IGetBusinessNotificationRes, null>({
            query: () => ({
                url: `api/notifications`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        postNewBusiness: builder.mutation<any, BusinessInfoPayload>({
            query: (businessData) => ({
                url: `api/business`,
                method: "POST",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
                body: businessData,
            }),
        }),
        postBusiness: builder.mutation<any, IBusinessUserInterface>({
            query: (businessData) => ({
                url: `auth/create/business-user`,
                method: "POST",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
                body: businessData,
            }),
        }),
        updateBusiness: builder.mutation<any, IBusinessInterfaceEdit>({
            query: ({ businessData, id }) => ({
                url: `api/business/${id}`,
                method: "PUT",
                body: businessData,
            }),
        }),
        postBusinessUpload: builder.mutation<any, FormData>({
            query: (file) => ({
                url: `api/business/upload`,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                body: file,
            }),
        }),
        getAllBusinessCategory: builder.query<any, string | undefined>({
            query: (name) => ({
                url: `api/business/category`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
        getBusinessReviews: builder.query<
            IReviewRes[],
            { type: "business" | "user"; id: string; query: string }
        >({
            query: ({ type, id, query = "/" }) => ({
                url: `api/business-reviews/${id}${query}`,
                method: "GET",
                headers: {
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                },
            }),
        }),
    }),
});

export const {
    useGetAllBusinessesQuery,
    useGetAllOpenBusinessesQuery,
    useGetOpenBusinessByIdQuery,
    useGetBusinessByIdQuery,
    useGetMyBusinessQuery,
    useGetEditSuggestionsQuery,
    useGetBusinessNotificationQuery,
    usePostNewBusinessMutation,
    usePostBusinessMutation,
    useUpdateBusinessMutation,
    usePostBusinessUploadMutation,
    usePostEditSuggestionsMutation,
    useGetBusinessByNameQuery,
    useGetAllBusinessCategoryQuery,
    useGetBusinessReviewsQuery,
} = businessApi;
