// TODO: Review history- Add no review history as a message under shoppers dashboard settings
// TODO: Review history- Add no review history as a message under shoppers dashboard settings
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left-black.svg";
import { BiChevronRight } from "react-icons/bi";
import { useEffect, useState } from "react";
import {
    IBusinessReview,
    IGetForumResData,
    useGetBusinessReviewsQuery,
    useGetForumsQuery,
} from "store/review";
import { useAuth } from "store/auth";
import { formatDate } from "utilities/dateFormatter";
import { HashLink } from "react-router-hash-link";

export const ReviewHistory = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useAuth();
    const { data } = useGetBusinessReviewsQuery({
        // id: ,
        id: user?.id || "",
        query: { type: "user" },
    });
    const [reviews, setReviews] = useState<IBusinessReview[]>(
        data?.data as unknown as IBusinessReview[]
    );

    const {
        data: getForumQuestion,
        refetch,
        isLoading,
    } = useGetForumsQuery({ MustBeParent: "true", userID: user?.id });

    // console.log(getForumQuestion?.data);

    useEffect(() => {
        setReviews(data?.data as unknown as IBusinessReview[]);
    }, [currentPage, data, reviews]);

    return (
        <>
            <div className="bg-grey-50">
                <div className="container">
                    <div className="grid grid-cols-3 m-auto">
                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer flex items-center gap-2"
                        >
                            <ChevronLeft className="text-4xl h-3 w-3" />
                            <p>Back</p>
                        </span>

                        <h3 className="text-xl md:text-3xl font-medium text-center">
                            My History
                        </h3>
                    </div>
                </div>

                <br />

                <div className="bg-white rounded-2xl flex items-center justify-between gap-6 max-w-2xl m-auto font-semibold px-4">
                    <p
                        onClick={() => setCurrentPage(1)}
                        className={`py-6 px-4 cursor-pointer ${
                            currentPage === 1
                                ? "border-b-4 text-primary-500 border-primary-500"
                                : ""
                        }`}
                    >
                        Review
                    </p>

                    <p
                        onClick={() => setCurrentPage(2)}
                        className={`py-6 px-4 cursor-pointer ${
                            currentPage === 2
                                ? "border-b-4 text-primary-500 border-primary-500"
                                : ""
                        }`}
                    >
                        Threads
                    </p>

                    {/* <p
            onClick={() => setCurrentPage(3)}
            className={`py-6 px-4 cursor-pointer ${
              currentPage === 3
                ? "border-b-4 text-primary-500 border-primary-500"
                : ""
            }`}
          >
            Questions
          </p> */}
                </div>

                <div className="container">
                    {(() => {
                        switch (currentPage) {
                            case 1:
                                return <ReviewHistoryTable reviews={reviews} />;
                            case 2:
                                return (
                                    <ThreadsHistoryTable
                                        threads={getForumQuestion?.data}
                                    />
                                );
                            case 3:
                                return (
                                    <QuestionsHistoryTable reviews={reviews} />
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            </div>
        </>
    );
};

function ThreadsHistoryTable({
    threads,
}: {
    threads: IGetForumResData[] | undefined;
}) {
    const navigate = useNavigate();

    return (
        <>
            <div className="m-auto max-w-5xl mt-10 flex flex-col gap-4 rounded-md bg-white">
                <div className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 font-semibold">
                    <div className="">Date</div>
                    <div className="">Business/product</div>
                    <div className="">Thread</div>
                    <div className=""></div>
                </div>
            </div>
            <div className="m-auto max-w-5xl mt-2 flex flex-col gap-2">
                {/* show no reviews if no reviews are found */}
                {threads?.length === 0 && (
                    <div className="text-center py-4">
                        You have no threads yet
                    </div>
                )}

                {/* map through reviews if there are any */}
                {threads?.map((thread) => (
                    <div
                        key={thread.id}
                        className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 bg-white rounded-md"
                    >
                        <div className="">
                            {formatDate(thread.createdAt).commaDateFormat}
                        </div>
                        <div className="">
                            {thread?.businessName || "no product name"}
                        </div>
                        <div className="md:col-span-2 col-span-1">
                            {thread.text}
                        </div>
                        <HashLink
                            to={`/forum#${
                                thread.id + thread.user.firstName + thread.text
                            }`}
                            className="hidden md:flex items-end justify-center flex-col cursor-pointer"
                        >
                            <BiChevronRight />
                        </HashLink>
                    </div>
                ))}
            </div>
        </>
    );
}

function ReviewHistoryTable({ reviews }: { reviews: IBusinessReview[] }) {
    const navigate = useNavigate();

    return (
        <>
            <div className="m-auto max-w-5xl mt-10 flex flex-col gap-4 rounded-md bg-white">
                <div className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 font-semibold">
                    <div className="">Date</div>
                    <div className="">Business/product</div>
                    <div className="">Description</div>
                    <div className=""></div>
                </div>
            </div>
            <div className="m-auto max-w-5xl mt-2 flex flex-col gap-2">
                {/* show no reviews if no reviews are found */}
                {reviews?.length === 0 && (
                    <div className="text-center py-4">
                        You have no reviews yet
                    </div>
                )}

                {/* map through reviews if there are any */}
                {reviews?.map((review) => (
                    <div
                        key={review.businessID}
                        className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 bg-white rounded-md"
                    >
                        <div className="">
                            {formatDate(review.createdAt).commaDateFormat}
                        </div>
                        <div className="">
                            {review?.business.businessName || "no product name"}
                        </div>
                        <div className="md:col-span-2 col-span-1">
                            {review.comment}
                        </div>
                        <HashLink
                            to={`/businesses/${review.businessID}#${review.id}`}
                            className="hidden md:flex items-end justify-center flex-col cursor-pointer"
                        >
                            <BiChevronRight />
                        </HashLink>
                    </div>
                ))}
            </div>
        </>
    );
}

function QuestionsHistoryTable({ reviews }: { reviews: IBusinessReview[] }) {
    return (
        <>
            <div className="m-auto max-w-5xl mt-10 flex flex-col gap-4 rounded-md bg-white">
                <div className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 font-semibold">
                    <div className="">Date</div>
                    <div className="">Business/product</div>
                    <div className="">Description</div>
                    <div className="">QS</div>
                </div>
            </div>
            <div className="m-auto max-w-5xl mt-2 flex flex-col gap-2">
                {/* show no reviews if no reviews are found */}
                {reviews?.length === 0 && (
                    <div className="text-center py-4">
                        You have no reviews yet
                    </div>
                )}

                {/* map through reviews if there are any */}
                {reviews?.map((review) => (
                    <div
                        key={review.businessID}
                        className="grid grid-cols-3 md:grid-cols-5 px-6 py-4 bg-white rounded-md"
                    >
                        <div className="">
                            {formatDate(review.createdAt).commaDateFormat}
                        </div>
                        <div className="">
                            {review?.business.businessName || "no product name"}
                        </div>
                        <div className="md:col-span-2 col-span-1">
                            {review.comment}
                        </div>
                        <div className="hidden md:flex items-end justify-center flex-col cursor-pointer">
                            <BiChevronRight />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
