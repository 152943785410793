import { FooterComponent, HeaderComponent } from "pages/landingPage";
import { CommentCard, ForumHero, SearchBar, comments } from "./components";
import { Redefine } from "pages/landingPage/components/redefine";
import { useEffect, useState } from "react";
import style from "../../pages/styles/forum.module.scss";
import { useGetForumsQuery } from "store/review";
import { useAuth } from "store/auth";

export const Forum = () => {
    const { user } = useAuth();
    const {
        data: getForumQuestion,
        refetch,
        isLoading,
    } = useGetForumsQuery({});

    const [currentPage, setCurrentPage] = useState(1);
    const commentsPerPage = 5;

    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;

    const currentComments = comments.slice(
        indexOfFirstComment,
        indexOfLastComment
    );

    const totalPages = Math.ceil(comments.length / commentsPerPage);

    useEffect(() => {
        // console.log(getForumQuestion);
        // console.log(user);
    }, [getForumQuestion, user]);

    return (
        <>
            <HeaderComponent role="general" />
            <section className=" mt-[80px] mb-[80px]">
                <ForumHero />
                <div className={style.frum_content_container}>
                    <SearchBar />
                    {/* <div className={style.community_cards}>
                        <Link
                            to={"/ask-community"}
                            className={`${style.card} ${style.card_left}`}
                        >
                            <div className={style.card_content}>
                                <span className={style.card_text}>
                                    Ask the community
                                </span>
                                <span className={style.card_icon}>
                                    <IoArrowForward />
                                </span>
                            </div>
                        </Link>

                        <div className={`${style.card} ${style.card_right}`}>
                            <div className={style.card_content}>
                                <span className={style.card_text}>
                                    Discuss a product/business
                                </span>
                                <span className={style.card_icon}>
                                    <IoArrowForward />
                                </span>
                            </div>
                        </div>
                    </div> */}

                    {isLoading || !getForumQuestion?.data?.length ? (
                        <div className="text-center mt-4">
                            <p className="text-gray-500">No discussion yet</p>
                        </div>
                    ) : null}

                    {getForumQuestion?.data?.map((thread) => (
                        <CommentCard
                            key={thread.id}
                            refetch={refetch}
                            thread={thread}
                        />
                    ))}
                    {/* 
                    <div className="flex justify-between items-center mt-4 flex-wrap">
                        <div className="flex space-x-2">
                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentPage(index + 1)}
                                    className={`px-2 py-1 rounded ${
                                        currentPage === index + 1
                                            ? "bg-gray-400 text-white"
                                            : "bg-gray-200 text-gray-400"
                                    }`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.min(prev + 1, totalPages)
                                    )
                                }
                                disabled={currentPage === totalPages}
                                className="px-3 py-1 rounded bg-gray-200 text-gray-400"
                            >
                                &gt;
                            </button>
                        </div>
                        <div className="text-gray-500">
                            Showing {indexOfFirstComment + 1} to{" "}
                            {Math.min(indexOfLastComment, comments.length)} of{" "}
                            {comments.length}
                        </div>
                    </div> */}
                </div>
            </section>
            <Redefine />

            <FooterComponent />
        </>
    );
};
