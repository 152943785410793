/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { Link, useNavigate } from "react-router-dom";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton, PrimaryDropzone } from "components/index";
import { IBusinessUserInterface } from "./business-Interface";
import { reactLocalStorage } from "reactjs-localstorage";
import { useEffect, useState } from "react";
import {
    usePostBusinessUploadMutation,
    usePostNewBusinessMutation,
} from "store/business";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as WhiteImgIcon } from "assets/svg/img-placeholder-white.svg";
import verifiedIcon from "assets/png/verified-blue-icon.png";
import { resolveApiError } from "utilities/errorHandling";
import { useLocation } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";

interface Props {
    nextPage: (num: number) => void;
}

export const BusinessUpload = ({ nextPage }: Props) => {
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const paramValue = query.get("business");
    const [files, setFiles] = useState<any>([]);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [preview, setPreview] = useState<string>("");
    const previousValues: IBusinessUserInterface = JSON.parse(
        reactLocalStorage.get("@business_info_payload") || "{}"
    );
    const [postNewBusiness, { isLoading }] = usePostNewBusinessMutation();
    const [postBusinessUpload, { upLoading }] = usePostBusinessUploadMutation();
    const { initNotification } = usePageNotificationProvider();
    const { handleSubmit } = useFormik({
        initialValues: {
            businessLogo: "",
        },
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });
    // const returnTo = localStorage.getItem("returnTo");
    // if (returnTo) {
    //   navigate(returnTo);
    //   return;
    // }

    const initRequest = async (payload?: any) => {
        setSubmitting(true);
        const form = new FormData();
        form.append("file", files[0].data);
        form.append("type", "logo");

        postBusinessUpload(form)
            .unwrap()
            .then((res: any) => {
                if (res.error)
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                initNotification({
                    message: res.message,
                    scheme: "success",
                });
                const updatedValues: IBusinessUserInterface = {
                    ...previousValues,
                    business: {
                        ...previousValues?.business,
                        businessLogo: res.data?.imageUrl,
                    },
                };

                postNewBusiness(updatedValues.business)
                    .unwrap()
                    .then((res: any) => {
                        initNotification({
                            scheme: "success",
                            message: res.message,
                        });
                        setSubmitted(true);
                        reactLocalStorage.remove("@business_info_payload");
                        setSubmitting(false);
                    })
                    .catch((err: any) => {
                        initNotification({
                            scheme: "error",
                            message:
                                resolveApiError(err) ||
                                err?.data?.message ||
                                err.error,
                        });
                        setSubmitting(false);
                    });
            })
            .catch((error: any) => {
                console.log(error);
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
                if (error?.data?.message === "Invalid token") {
                    initNotification({
                        message: "Session expired, please login again",
                        scheme: "error",
                    });
                    navigate("/login", { replace: true });
                }
            });
    };

    useEffect(() => {
        const image = files[0]?.data;
        if (image) setPreview(URL.createObjectURL(image));
    }, [files]);

    return (
        <>
            {submitted && <SuccessPopUp />}
            <AuthHeaderText
                title="Add your business logo"
                // subTitle="Add Photos of your business."
                headingClassName="text-black text-3xl font-medium mb-5"
            />
            <form onSubmit={handleSubmit} className="">
                {!preview ? (
                    <PrimaryDropzone type="logo" setFile={setFiles} />
                ) : (
                    <div className="">
                        <img
                            src={preview}
                            className="w-full h-80 object-contain"
                            alt="preview"
                        />
                        <br />
                        <PrimaryButton
                            type="reset"
                            onClick={() => {
                                setPreview("");
                                setFiles([]);
                            }}
                            className="primary-btn py-6 px-10"
                        >
                            <>Clear</>
                        </PrimaryButton>
                    </div>
                )}

                <div className="flex items-center gap-4 mt-10">
                    <PrimaryButton
                        type="submit"
                        className="primary-btn flex gap-4 py-6 px-10"
                        disabled={preview.length < 1}
                        isLoading={upLoading || isLoading || submitting}
                    >
                        <WhiteImgIcon className="w-4 h-4 text-base" />
                        <p>Submit</p>
                    </PrimaryButton>
                </div>
            </form>
            {upLoading && <LoadingPopUp />}
            {isLoading && <LoadingPopUp />}
            {submitting && <LoadingPopUp />}{" "}
        </>
    );
};

const SuccessPopUp = () => {
    return (
        <div className="fixed bg-white inset-0 z-10 flex flex-col items-center">
            <Link
                to={"/"}
                className="flex items-center justify-end w-full py-4 px-6"
            >
                <CloseIcon className="text-3xl w-8 h-8" />
            </Link>
            <div className="p-6 flex items-center flex-col justify-center gap-8 h-full max-h-96 w-full my-auto">
                <img src={verifiedIcon} alt="verifiedIcon" />
                <p className="font-semibold text-2xl text-primary-500 uppercase">
                    Yippee!!
                </p>
                <p className="text-lg font-normal max-w-[500px] text-grey-500 text-center">
                    Your profile information has been successfully submitted.
                    <br />
                    You will receive an email notification once your profile has
                    been reviewed and approved by our admin team.
                    <br />
                    <br />
                    Thank you for your patience.
                </p>
                <Link to={"/"} className="primary-btn flex gap-4 py-6 px-10">
                    Go to Landing Page
                </Link>
            </div>
        </div>
    );
};

export const LoadingPopUp = () => {
    return (
        <div className="fixed bg-white/80 inset-0 z-10 flex flex-col items-center">
            <div className="p-6 flex items-center flex-col justify-center gap-8 h-full max-h-96 w-full my-auto">
                <Spinner color={"#001c89"} />
            </div>
        </div>
    );
};
