import { PrimaryButton } from "components/buttons";
import {
    AuthHeaderText,
    CustomPasswordInput,
    PrimaryCheckBox,
    PrimaryInput,
} from "components";
// import { AuthHeaderText, CustomPasswordInput } from "components/custom";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginValidationSchema } from "validations/auth";
import { SocialAuthButton } from "./components";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { useDispatch } from "react-redux";
import { LoginPayload, setCredential, useAuth } from "store/auth";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import {
    sendEmailVerification,
    signInWithEmailAndPassword,
    UserCredential,
} from "firebase/auth";
import { auth } from "firebaseConfig/config";
import {
    ProfileInformationResponse,
    useGetProfileInformationAltMutation,
    useGetProfileInformationWithTokenQuery,
} from "store/profile";
import { mapAuthCodeToMessage } from "utilities/general";
import { resolveApiError } from "utilities/index";

export const LoginPage = () => {
    const { user, token: access_token } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [getProfile, { isLoading: profileLoading }] =
        useGetProfileInformationAltMutation();
    const returnTo = localStorage.getItem("returnTo");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const { initNotification } = usePageNotificationProvider();
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                email: "",
                password: "",
            },
            validationSchema: LoginValidationSchema,
            onSubmit: (e) => initRequest(e),
        });

    const isUserEmailVerified = (res: UserCredential) => {
        if (res.user.emailVerified) {
            return true;
        }
        sendEmailVerification(res.user, {
            url: `${process.env.REACT_APP_BASE_URL}/verify-email?email=${res.user.email}`,
            handleCodeInApp: true,
        });
        navigate(`/verify-email?email=${res.user.email}`, {
            state: { from: location.pathname },
        });
        return false;
    };

    const initRequest = async (payload?: LoginPayload) => {
        if (payload?.email && payload?.password) {
            setIsLoading(true);
            await signInWithEmailAndPassword(
                auth,
                payload?.email,
                payload?.password
            )
                .then(async (res) => {
                    const verifiedUser = await isUserEmailVerified(res);
                    const token = await res.user.getIdToken();
                    if (verifiedUser) {
                        getProfile(token)
                            .unwrap()
                            .then(async (res: ProfileInformationResponse) => {
                                setIsLoading(false);
                                if (
                                    res?.error ||
                                    (res?.status !== 200 && res?.status !== 201)
                                ) {
                                    initNotification({
                                        message:
                                            res?.message ||
                                            "An error occured. Please try again later",
                                        scheme: "error",
                                    });
                                    return;
                                }

                                initNotification({
                                    message: "You have successfully logged in.",
                                });
                                dispatch(
                                    setCredential({
                                        user: res?.data?.user
                                            ? res?.data?.user
                                            : res?.data,
                                        access_token: token,
                                    })
                                );
                                if (returnTo) {
                                    navigate(returnTo);
                                    return;
                                }
                                if (location.state) {
                                    navigate(`${location.state.from}`);
                                } else {
                                    if (res?.data?.role) {
                                        if (res?.data?.role === "member") {
                                            navigate(`/businesses`);
                                        } else if (
                                            res?.data?.role === "businessOwner"
                                        ) {
                                            navigate(`/business/dashboard`);
                                        }
                                    }
                                    return;
                                }
                            })
                            .catch((error) => {
                                setIsLoading(false);
                                if (
                                    error?.data?.status === 401 ||
                                    error?.data?.message === "User not found"
                                ) {
                                    dispatch(
                                        setCredential({
                                            user: user,
                                            access_token: token,
                                        })
                                    );
                                    navigate("/account-type");
                                    return;
                                }

                                if (error?.data?.status === 500) {
                                    initNotification({
                                        message:
                                            "An error occured, please try again later.",
                                        scheme: "error",
                                    });
                                    return;
                                }
                                initNotification({
                                    message: resolveApiError(error),
                                    scheme: "error",
                                });
                            });
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    initNotification({
                        message: mapAuthCodeToMessage(err.code),
                        scheme: "error",
                    });
                });
        }
    };

    return (
        <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
            <Link to="/" className="font-extrabold text-2xl text-gray-500">
                <Logo />
            </Link>
            <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
                <AuthHeaderText
                    title="Welcome back"
                    subTitle="Enter your details to continue"
                    headingClassName="text-black text-3xl font-medium text-center mb-2"
                    subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
                />
                <form onSubmit={handleSubmit} className="">
                    <div className="mt-3 d-flex flex-column w-100 gap-4">
                        <div className="my-5">
                            <PrimaryInput
                                type="email"
                                label="Email Address"
                                name="email"
                                placeholder="example@mail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={Boolean(errors?.email && touched?.email)}
                                bottomText={errors?.email || ""}
                            />
                        </div>
                        <div className="">
                            <CustomPasswordInput
                                label="Password"
                                name="password"
                                placeholder="********"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={Boolean(
                                    touched.password && errors?.password
                                )}
                                bottomText={errors?.password}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <div>
                            <PrimaryCheckBox
                                size={"lg"}
                                inlineText="Remember me"
                            />
                        </div>
                        <Link to="/forgot-password" className="text-[#001C89]">
                            Forgot password
                        </Link>
                    </div>
                    <PrimaryButton
                        type="submit"
                        className="btn-lg primary-btn w-full mt-8 align-self-baseline"
                        isLoading={isLoading}
                    >
                        Log In
                    </PrimaryButton>
                </form>
                <h4 className="divider">Or continue with</h4>
                <div className="flex items-center justify-center gap-5">
                    <SocialAuthButton provider="google" />
                </div>
            </div>
            <p className="text-center text-[#383838]">
                Don't have an account yet?{" "}
                <Link
                    to="/sign-up/individual"
                    className="text-[#001C89] text-base font-medium fw-700 text-decoration-none"
                >
                    Sign Up
                </Link>
            </p>
        </div>
    );
};
