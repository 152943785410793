import { useNavigate } from "react-router";
import { BiChevronLeft } from "react-icons/bi";
import style from "./../../styles/dashboard.module.scss";
import { useAuth } from "store/auth";
import {
    useGetEditSuggestionsQuery,
    useGetMyBusinessQuery,
} from "store/business";
import { formatDate } from "utilities/dateFormatter";
import { getNameInitials } from "utilities/general";

export const EditSuggestion = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { data: businessInfo } = useGetMyBusinessQuery(user?.id || "");
    const { data, refetch, isLoading } = useGetEditSuggestionsQuery(
        businessInfo.data[0].id
    );

    return (
        <div>
            <div className={`container ${style.photos_section}`}>
                <span
                    className={`${style.back_btn} flex items-center mb-3`}
                    onClick={() => navigate(-1)}
                >
                    <BiChevronLeft /> back
                </span>
                <div className="bg-white py-8 px-6 rounded">
                    <h2 className="text-3xl font-semibold">Read suggestions</h2>
                    <br />
                    {data?.data?.length ? (
                        <div className="flex flex-col gap-4">
                            {data?.data.map(
                                (suggestion: any, index: number) => (
                                    <div
                                        key={index}
                                        className="flex flex-col gap-4 px-5 py-6 pb-8 rounded-3xl w-fit block border-2"
                                    >
                                        <div className="flex items-center gap-4 text-base font-semibold">
                                            {/* <img
                                                src=""
                                                alt=""
                                                className="w-10 h-10 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                                            /> */}
                                            <div className="w-10 h-10 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                                                {getNameInitials(
                                                    suggestion.user.firstName +
                                                        " " +
                                                        suggestion.user.lastName
                                                )}
                                            </div>
                                            <p>
                                                {suggestion.user.firstName}{" "}
                                                {suggestion.user.lastName}
                                            </p>
                                            <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                                            <p>
                                                {
                                                    formatDate(
                                                        suggestion.createdAt
                                                    ).slashNumberDateFormat
                                                }
                                            </p>
                                        </div>
                                        <div className="text-base font-medium text-gray-800">
                                            <p>{suggestion.Suggestion}</p>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center text-lg font-semibold tracking-wide mt-10 text-gray-400">
                            <p>You have no suggestions</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
