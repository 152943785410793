import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left-black.svg";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { auth } from "firebaseConfig/config";
import { useDispatch } from "react-redux";
import { setCredential } from "store/auth";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";

export const AccountSetting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { initNotification } = usePageNotificationProvider();

    const handleLogout = () => {
        auth.signOut();
        initNotification({
            scheme: "success",
            message: "You've been logged out",
        });
        dispatch(
            setCredential({
                user: null,
                access_token: null,
            })
        );
        window.location.reload();
    };

    return (
        <>
            <div className="bg-grey-50 min-h-screen">
                <div className="container">
                    <div className="grid grid-cols-3 m-auto">
                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer flex items-center gap-2"
                        >
                            <ChevronLeft className="text-4xl h-3 w-3" />
                            <p>Back</p>
                        </span>

                        <h3 className="text-xl md:text-3xl font-medium text-center">
                            Account Settings
                        </h3>
                    </div>
                </div>

                <div className="container">
                    <div className="m-auto max-w-xl mt-10 flex flex-col gap-4">
                        <div
                            onClick={() => navigate("/shopper/profile")}
                            className="flex items-center justify-between py-5 px-6 rounded-md bg-white cursor-pointer"
                        >
                            <p className="font-medium">Profile</p>
                            <span>
                                <BiChevronDown className="text-2xl w-6 h-6" />
                            </span>
                        </div>

                        <div
                            onClick={() => navigate("/shopper/change-password")}
                            className="flex items-center justify-between py-5 px-6 rounded-md bg-white cursor-pointer"
                        >
                            <p className="font-medium">Change password</p>
                            <span>
                                <BiChevronDown className="text-2xl w-6 h-6" />
                            </span>
                        </div>

                        <div
                            onClick={() => navigate("/shopper/review-history")}
                            className="flex items-center justify-between py-5 px-6 rounded-md bg-white cursor-pointer"
                        >
                            <p className="font-medium">My History</p>
                            <span>
                                <BiChevronDown className="text-2xl w-6 h-6" />
                            </span>
                        </div>

                        {/* <div className="flex items-center justify-between py-5 px-6 rounded-md bg-white">
                            <p className="font-medium">Notification settings</p>
                            <span>
                                <BiChevronDown className="text-2xl w-6 h-6" />
                            </span>
                        </div> */}

                        <div
                            onClick={handleLogout}
                            className="flex items-center justify-between py-5 px-6 rounded-md bg-white cursor-pointer"
                        >
                            <p className="font-medium text-red-500">Log Out</p>
                            <span>
                                <BiChevronRight className="text-2xl w-6 h-6" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
