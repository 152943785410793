import { BsTelephonePlus } from "react-icons/bs";
import style from "../../styles/searchComponent.module.scss";
import { MdOutlineDirections } from "react-icons/md";
import { RiEditBoxLine } from "react-icons/ri";
import { IoOpenOutline } from "react-icons/io5";
import ThreeStars from "assets/svg/3.5starRating.svg";
import {
    Input,
    InputGroup,
    InputLeftElement,
    Progress,
} from "@chakra-ui/react";
import { FC, MouseEventHandler, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TfiWorld } from "react-icons/tfi";
import SearchIcon from "assets/svg/searchPlaceHolder.svg";
import ProfilePicture from "assets/svg/profilePicturePlaceHolder.svg";
import { SlOptions, SlPicture, SlOptionsVertical } from "react-icons/sl";
import { FaRegStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { IoHeartOutline } from "react-icons/io5";
import { AVerifiedReview } from "./aVerifiedReview";
import { OverFlowPages } from "./overFlowPages";
import { PrimaryButton } from "components/buttons";
import { MdTune } from "react-icons/md";
import { AverageRatingsComponent } from "./averageRatingsComponent";
import { ReadReview } from "pages";
import { IBusinessInterface } from "pages/auth/business/business-Interface";
import { useModalProvider } from "providers/index";
import { FiPlus } from "react-icons/fi";

interface Business {
    business: IBusinessInterface;
    reviewsAndRatings: any;
    highlight: boolean;
}

export const ReviewTab: FC<Business> = ({
    business,
    reviewsAndRatings,
    highlight,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const { initModal } = useModalProvider();

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <div className="container">
                <div className=" grid grid-cols-8 lg:gap-14">
                    <div className="lg:col-span-5 col-span-8">
                        <AverageRatingsComponent
                            ratings={reviewsAndRatings}
                            reviews={reviewsAndRatings?.reviews}
                        />

                        <ReadReview canReply={false} />
                        {/* 
            <div className="pt-4 pb-14 mt-9 border-y border-[#C2C2C2]">
              <h1 className="text-[28px] text-[#0B0B0B] font-medium">
                Ask the community a question
              </h1>

              <PrimaryButton
                onClick={() =>
                  initModal((prev) => ({
                    ...prev,
                    askTheCommunity: true,
                  }))
                }
                className="text-lg px-0 text-primary-500 !font-semibold bg-transparent border-0 "
              >
                <FiPlus className="h-5 w-5 me-1" />
                Ask a question
              </PrimaryButton>
              <p className="text-[#0B0B0B] font-medium mt-3">
                No questions yet, be the first to ask a question.
              </p>
            </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export function Dropdown(props: { isVisible: any }) {
    return (
        <>
            <div>
                {props.isVisible ? (
                    <ul>
                        <li>Option 1</li>
                        <li>Option 2</li>
                        <li>Option 3</li>
                    </ul>
                ) : null}
            </div>
        </>
    );
}

function Button(props: {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}) {
    return (
        <button className={style.sortByButton} onClick={props.onClick}>
            <span>Sort by</span>
            <IoIosArrowDown />
        </button>
    );
}
