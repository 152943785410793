import { useState } from "react";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left-black.svg";
import { PrimaryButton } from "components/buttons";
import { AuthHeaderText, CustomPasswordInput } from "components";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { auth } from "firebaseConfig/config";
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { toast } from "react-toastify";
import { mapAuthCodeToMessage } from "utilities/general";

export const ChangePassword = () => {
    const navigate = useNavigate();
    const [isLoading] = useState(false);
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                currentPassword: "",
                newPassword: "",
            },
            validationSchema: null,
            onSubmit: (e) => initRequest(e),
        });

    const initRequest = async (payload: any) => {
        // console.log(payload);
        const user = auth.currentUser;

        if (user) {
            const credential = EmailAuthProvider.credential(
                user.email || "",
                payload.currentPassword
            );

            reauthenticateWithCredential(user, credential)
                .then(() => {
                    updatePassword(user, payload.newPassword)
                        .then(() => {
                            navigate("/login");
                        })
                        .catch((error) => {
                            toast.error(
                                `Error updating password: ${mapAuthCodeToMessage(
                                    error.code
                                )}`
                            );
                        });
                })
                .catch((error) => {
                    toast.error(
                        `Error reauthenticate: ${mapAuthCodeToMessage(
                            error.code
                        )}`
                    );
                });
        } else {
            toast.error("No user is signed in.");
        }
    };

    return (
        <>
            <div className="bg-grey-50 min-h-screen">
                <div className="container">
                    <span
                        onClick={() => navigate(-1)}
                        className="cursor-pointer flex items-center gap-2"
                    >
                        <ChevronLeft className="text-4xl h-3 w-3" />
                        <p>Back</p>
                    </span>
                </div>

                <br />

                <div className="auth-form-wrapper bg-white py-10 px-8 rounded-2xl max-w-[450px] w-[90%] mx-auto">
                    <AuthHeaderText
                        title="Change password"
                        subTitle="Enter your previous password and your current password"
                        headingClassName="text-black text-3xl font-medium text-center mb-2"
                        subheadingClassName="text-[#919191] text-base font-normal text-center mb-10"
                    />
                    <form onSubmit={handleSubmit} className="">
                        <div className="mt-3 d-flex flex-column w-100 gap-6">
                            <div className="">
                                <CustomPasswordInput
                                    label="Current Password"
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    placeholder="********"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.currentPassword}
                                    error={Boolean(
                                        touched.currentPassword &&
                                            errors?.currentPassword
                                    )}
                                    bottomText={errors?.currentPassword}
                                />
                            </div>
                            <div className="">
                                <CustomPasswordInput
                                    label="New Password"
                                    name="newPassword"
                                    placeholder="********"
                                    autoComplete="new-password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPassword}
                                    error={Boolean(
                                        touched.newPassword &&
                                            errors?.newPassword
                                    )}
                                    bottomText={errors?.newPassword}
                                />
                            </div>
                        </div>

                        <PrimaryButton
                            type="submit"
                            className="btn-lg primary-btn w-full mt-8 align-self-baseline"
                            isLoading={isLoading}
                        >
                            Change Password
                        </PrimaryButton>
                    </form>
                </div>
            </div>
        </>
    );
};
