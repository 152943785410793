import { HeaderComponent } from "pages/landingPage/components";
import { AuthVerifyContainer } from "pages/auth/components";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "store/auth";
import { Link } from "react-router-dom";

export const ShopperContainer = () => {
  //   const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user?.role === "businessOwner") {
      navigate("/business/dashboard");
    }
  }, [navigate, user?.role]);

  // console.log(user);
  return (
    <div className="dashboard-container">
      {/*========== HEADER =========== */}
      <HeaderComponent role="shopper" />
      {/*========== END OF HEADER =========== */}

      <section className="bg-grey-50" style={{ minHeight: "100vh" }}>
        <div className="d-flex align-items-center">
          {/* <SideBarComponent /> */}
          <div
            className="pt-[90px] pb-14 bg-grey-50"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="p-md-4 p-2 pb-0 bg-grey-50 h-100"
              // style={{ minHeight: "100vh" }}
            >
              <div className="pt-2 h-100">
                <AuthVerifyContainer />
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 px-4 bg-white sticky bottom-0">
          <div className="md:flex item-center justify-end md:gap-7 gap-y-3 grid grid-cols-2">
            <Link to={"/privacy-policy"}>Privacy policy</Link>
            <Link to={"/terms-condition"}>Terms and conditions</Link>
            <p>All right reserved. Trade tracka {new Date().getFullYear()}</p>
          </div>
        </div>
      </section>
    </div>
  );
};
