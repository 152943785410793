export function getTimeFromTimestamp(timestamp: string): string {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Extract hours, minutes, and seconds in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Format the time in HH:MM:SS format with zero-padding
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}