import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import React, { useEffect, useState } from "react";
import { BiChevronDown, BiSend } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa6";
import { FiEdit, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { GoShareAndroid, GoTrash } from "react-icons/go";
import { useNavigate } from "react-router";
import { useAuth } from "store/auth";
// import { GoShareAndroid } from "react-icons/go";
import {
    IGetForumAnswersResData,
    IGetForumResData,
    IGetWriteForumParam,
    useDeleteForumMutation,
    useGetForumsQuery,
    useGetQuestionStatsQuery,
    usePostReactForumQuestionReplyMutation,
    usePostWriteForumAnswerMutation,
} from "store/review";
import { formatDate } from "utilities/dateFormatter";
import { resolveApiError } from "utilities/errorHandling";
import { getInitials } from "utilities/formatting";

interface CommentCardProps {
    thread: IGetForumResData;
    refetch?: () => void;
}

export const CommentCard: React.FC<CommentCardProps> = ({
    thread,
    refetch: refetchParent,
}) => {
    const { user, token: access_token } = useAuth();
    const navigate = useNavigate();
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [deleteForum, { isLoading: deleting }] = useDeleteForumMutation();
    const [postWriteForumAnswer, { isLoading }] =
        usePostWriteForumAnswerMutation();
    const [postReactForumQuestionReply] =
        usePostReactForumQuestionReplyMutation();
    const forumParameters: IGetWriteForumParam = user?.id
        ? {
              id: thread.id,
              MustBeParent: "true",
              callerID: user?.id,
          }
        : {};
    const { data: getForumAnswers, refetch } =
        useGetForumsQuery(forumParameters);

    const { data: getForumReplies, refetch: refetchReplies } =
        useGetForumsQuery({
            MustBeParent: "false",
            parentID: thread.id as any,
        });

    const { data: stats, refetch: refetchReact } = useGetQuestionStatsQuery({
        id: thread.id,
    });
    const commentStats = stats?.data;
    const [reply, setReply] = useState("");
    const { initNotification } = usePageNotificationProvider();

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: thread?.text || "Check this out!",
                    text: `${thread?.user?.firstName} started a thread on ${thread?.businessName}. Here's what they said: ${thread?.text}`,
                    url: `${window.location.href}#${
                        thread.id + thread.user.firstName + thread.text
                    }`, // This will share the current page URL
                })
                .then(() => console.log("Content shared successfully!"))
                .catch((error) => console.error("Error sharing:", error));
        } else {
            alert("Sharing is not supported on your browser.");
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        postWriteForumAnswer({
            businessName: thread.businessName,
            parentID: thread.id,
            text: reply,
        })
            .unwrap()
            .then((res: any) => {
                if (res.error) {
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                    return;
                }

                initNotification({
                    message: "Comment successfully posted.",
                });
                setReply("");
                refetch();
                refetchReact();
                refetchReplies();
                refetchParent?.();
            })
            .catch((error: any) => {
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    const handleReaction = (id: number, state: "like" | "dislike" | "none") => {
        console.log(id, state);
        postReactForumQuestionReply({ id, state })
            .unwrap()
            .then((res) => {
                refetch();
                refetchReact();
                refetchReplies();
                refetchParent?.();
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const handleDeleteForum = (id: string) => {
        deleteForum(id)
            .unwrap()
            .then((res: any) => {
                // console.log(res);
                refetch();
                refetchReact();
                refetchReplies();
                refetchParent?.();
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    useEffect(() => {
        // console.log(stats);
    }, [stats]);

    return (
        <>
            <div
                key={thread.id.toString() + thread.userId.toString()}
                id={thread.id + thread.user.firstName + thread.text}
                className="bg-white p-5 py-8 my-5"
            >
                <div className="">
                    <div className="flex gap-2 text-2xl font-semibold mb-5">
                        <h2>{thread?.text}</h2>
                    </div>

                    <div className="flex gap-4 items-center mb-5">
                        {/* {thread.user.photoUrl.length ? (
                            <img
                                src={thread.user.photoUrl}
                                alt=""
                                className="w-6 h-6 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"
                            />
                        ) : (
                            <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                                {getInitials(thread.user.firstName)}
                            </div>
                        )}*/}
                        <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                            {getInitials(thread.user.firstName)}
                        </div>
                        <p className="font-normal">
                            {thread?.user?.firstName} Started a thread on
                            <span className="text-primary-500 font-bold">
                                {" "}
                                {thread?.businessName}
                            </span>
                        </p>
                        <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                        <p>
                            {formatDate(thread.createdAt).slashNumberDateFormat}
                        </p>
                    </div>

                    <div className="flex gap-6 items-center justify-between">
                        <div className="flex gap-6 items-center py-4">
                            <div
                                onClick={() =>
                                    handleReaction(thread.id, "like")
                                }
                                className="cursor-pointer"
                            >
                                <FiThumbsUp
                                    className={`text-2xl cursor-pointer text-grey-600 -mb-2 ${
                                        getForumAnswers?.data &&
                                        getForumAnswers.data.length > 0 &&
                                        getForumAnswers.data[0].reaction ===
                                            "like"
                                            ? "text-primary-500 fill-primary-500"
                                            : ""
                                    }`}
                                />
                                <span className="ml-5 text-sm">
                                    {commentStats?.likes}
                                </span>
                            </div>
                            <div
                                onClick={() =>
                                    handleReaction(thread.id, "dislike")
                                }
                                className="cursor-pointer"
                            >
                                <FiThumbsDown
                                    className={`text-2xl cursor-pointer text-grey-600 -mb-2 ${
                                        getForumAnswers?.data &&
                                        getForumAnswers.data.length > 0 &&
                                        getForumAnswers.data[0].reaction ===
                                            "dislike"
                                            ? "text-primary-500 fill-primary-500"
                                            : ""
                                    }`}
                                />
                                <span className="ml-5 text-sm">
                                    {commentStats?.dislikes}
                                </span>
                            </div>
                            <div>
                                <FaRegComment className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                                <span className="ml-5 text-sm">
                                    {thread?.answers?.length ||
                                        commentStats?.replies}
                                </span>
                            </div>
                            <div onClick={handleShare}>
                                <GoShareAndroid className="text-2xl cursor-pointer text-grey-600 -mb-2" />
                            </div>
                        </div>

                        {/* //!! EDIT DELETE FEATURE WORKING, JUST UNCOMMENT */}
                        {thread?.user?.id === (user?.id as any) && (
                            <div className="md:ml-auto md:w-3/5 text-4xl flex items-center justify-end gap-3 md:gap-6">
                                <button
                                    onClick={() => {
                                        localStorage.setItem(
                                            "thread-state",
                                            JSON.stringify({
                                                state: "edit",
                                                thread: thread,
                                            })
                                        );
                                        navigate(
                                            `/create-thread?thread=${thread.id}`
                                        );
                                    }}
                                    className="cursor-pointer text-primary-600 border-0 py-2 px-3 flex gap-2 items-center"
                                >
                                    <FiEdit />
                                    <h2 className="text-primary-500 md:inline hidden">
                                        Edit
                                    </h2>
                                </button>
                                <button
                                    onClick={() =>
                                        window.confirm(
                                            "Are you sure you want to delete your thread?"
                                        ) &&
                                        handleDeleteForum(
                                            thread?.id as unknown as string
                                        )
                                    }
                                    className="cursor-pointer text-red-600 border-0 py-2 px-3 flex gap-2 items-center"
                                >
                                    <GoTrash />
                                    <h2 className="text-red-500 md:inline hidden">
                                        Delete
                                    </h2>
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="border-2 rounded-2xl p-4 py-6 mb-4">
                    <div className="flex justify-between items-center">
                        <div
                            className={`flex gap-2 text-2xl font-semibold mb-5 items-center transition-all duration-500 ease-in-out`}
                        >
                            <div className="">
                                <div className="w-10 h-10 p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center"></div>
                            </div>
                            <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                            <p className="text-lg">
                                This thread has {thread?.answers?.length || 0}{" "}
                                {thread?.answers?.length > 1
                                    ? "replies"
                                    : "reply"}
                            </p>
                        </div>

                        <div
                            onClick={() => setIsCommentOpen(!isCommentOpen)}
                            className="cursor-pointer"
                        >
                            <BiChevronDown
                                className={`text-4xl cursor-pointer" ${
                                    isCommentOpen ? "rotate-180" : ""
                                }`}
                            />
                        </div>
                    </div>
                    <div
                        className={`${
                            isCommentOpen ? "h-auto pt-4" : "h-0"
                        } overflow-hidden transition-all duration-500 ease-in-out`}
                    >
                        {!thread.answers?.length && (
                            <p className="text-sm text-grey-500 text-center">
                                No replies yet
                            </p>
                        )}
                        {getForumReplies?.data?.map(
                            (reply: IGetForumAnswersResData, idx: number) => (
                                <CommentList
                                    key={idx}
                                    reply={reply}
                                    threadId={thread.id}
                                />
                            )
                        )}
                    </div>
                </div>

                <div className="relative">
                    <input
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        type="text"
                        className="w-full bg-grey-100 rounded-2xl p-2 py-3"
                        placeholder="Write a reply..."
                    />

                    <button
                        disabled={reply.length < 1 || isLoading}
                        onClick={handleSubmit}
                        className="absolute bottom-[15%] rounded-xl flex items-center justify-center text-xl active:scale-95 bg-primary-500 text-white border-0 right-5 w-8 h-8"
                    >
                        <BiSend />
                    </button>
                </div>
            </div>
        </>
    );
};

// ?? REPLIES TO POST COMPONENT
// ?? REPLIES TO POST COMPONENT
// ?? REPLIES TO POST COMPONENT
// ?? REPLIES TO POST COMPONENT
// ?? REPLIES TO POST COMPONENT
// ?? REPLIES TO POST COMPONENT
export default function CommentList(props: {
    threadId: number;
    reply: IGetForumAnswersResData;
}) {
    const { user: authUser } = useAuth();
    const { text, user, createdAt, id } = props.reply;
    const { data: stats, refetch: refetchReact } = useGetQuestionStatsQuery({
        id: id,
    });
    const commentStats = stats?.data;
    const forumParameters: IGetWriteForumParam = user?.id
        ? {
              id: props.reply.id,
              MustBeParent: "false",
              parentID: props.threadId as unknown as string,
              callerID: authUser?.id,
          }
        : {};
    const { data: getForumAnswers, refetch } =
        useGetForumsQuery(forumParameters);
    const [postReactForumQuestionReply] =
        usePostReactForumQuestionReplyMutation();

    const handleReaction = (id: number, state: "like" | "dislike" | "none") => {
        postReactForumQuestionReply({ id, state })
            .unwrap()
            .then((res) => {
                refetch();
                refetchReact();
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    // console.log(getForumAnswers);

    return (
        <div className="pb-4 pt-6 border-b-2">
            <div className="flex gap-4 items-center mb-5">
                <div className="w-6 h-6 text-xs p-0 font-normal text-center m-0 bg-primary-500 text-white rounded-full mb-0 flex items-center justify-center">
                    {getInitials(user?.firstName || "XX")}
                </div>
                <p className="font-normal">{user?.firstName}</p>
                <span className="w-2 h-2 rounded-full bg-gray-300 block" />
                <p>{formatDate(createdAt).slashNumberDateFormat}</p>
            </div>

            <div className="">
                <p>{text}</p>
            </div>

            <div className="flex gap-6 items-center py-4">
                <div onClick={() => handleReaction(id, "like")}>
                    <FiThumbsUp
                        className={`text-2xl cursor-pointer text-grey-600 -mb-2 ${
                            getForumAnswers?.data[0].reaction === "like"
                                ? "text-primary-500 fill-primary-500"
                                : ""
                        }`}
                    />
                    <span className="ml-5 text-sm">{commentStats?.likes}</span>
                </div>
                <div onClick={() => handleReaction(id, "dislike")}>
                    <FiThumbsDown
                        className={`text-2xl cursor-pointer text-grey-600 -mb-2 ${
                            getForumAnswers?.data[0].reaction === "dislike"
                                ? "text-primary-500 fill-primary-500"
                                : ""
                        }`}
                    />
                    <span className="ml-5 text-sm">
                        {commentStats?.dislikes}
                    </span>
                </div>
            </div>
        </div>
    );
}
