import { Tooltip } from "@chakra-ui/react";
import { PrimaryButton } from "components/buttons";
import { PrimaryCheckBox } from "components/inputs";
import moment from "moment";
import { IBusinessHours } from "pages/auth/business/business-Interface";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { FC, useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";

interface SchedulerComponentFormProps {
    scheduleDays: IBusinessHours[];
    setScheduleDays: (scheduleDays: IBusinessHours[]) => void;
}

const convertTo24HourFormat = (time12h: string) => {
    return moment(time12h, ["h:mm A"]).format("HH:mm");
};

export const SchedulerComponentForm: FC<SchedulerComponentFormProps> = ({
    scheduleDays = [],
    setScheduleDays,
}) => {
    const { initNotification } = usePageNotificationProvider();
    const formattedTimeFormat = scheduleDays.length
        ? scheduleDays.map((day) => {
              return {
                  ...day,
                  openingTime: convertTo24HourFormat(day.openingTime),
                  closingTime: convertTo24HourFormat(day.closingTime),
              };
          })
        : [];
    const [schedule, setSchedule] =
        useState<IBusinessHours[]>(formattedTimeFormat);
    const [newScheduleDays, setNewScheduleDays] = useState<IBusinessHours>({
        closingTime: "05:00",
        dayOfWeek: "Monday",
        openingTime: "08:00",
        closed: false,
    });

    const addClassToSchedule = () => {
        const exists = schedule.some(
            (item) => item.dayOfWeek === newScheduleDays.dayOfWeek
        );

        if (exists) {
            initNotification({
                message: "That day has already been selected.",
                scheme: "error",
            });
            return;
        }

        setSchedule((prev) => {
            return [...prev, newScheduleDays];
        });

        setNewScheduleDays({
            dayOfWeek: "Monday",
            openingTime: "08:00",
            closingTime: "05:00",
            closed: false,
        });
    };
  useEffect(() => {
    setScheduleDays([
      ...schedule.map((s) => {
        return {
          ...s,
          openingTime: moment(s?.openingTime, "HH:mm").format("hh:mm A"),
          closingTime: moment(s?.closingTime, "HH:mm").format("hh:mm A"),
        };
      }),
    ]);
  }, [schedule]);

    const deleteClassFromSchedule = (index: number) => {
        const newSchedule = [...schedule];
        newSchedule.splice(index, 1);
        setSchedule(newSchedule);
    };

    const handleSaveSchedule = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        // if (!schedule[0]?.subject) {
        localStorage.setItem("tutorSchedule", JSON.stringify(schedule));
        initNotification({ message: "Schedule saved!", scheme: "success" });
        // setSchedule([]);
        // }
    };

    useEffect(() => {
        setScheduleDays([
            ...schedule.map((s) => {
                return {
                    ...s,
                    openingTime: moment(s.openingTime, "HH:mm").format(
                        "hh:mm A"
                    ),
                    closingTime: moment(s.closingTime, "HH:mm").format(
                        "hh:mm A"
                    ),
                };
            }),
        ]);
    }, [schedule]);

    const handleChange = (e: { target: any }) => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        setNewScheduleDays((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEditChange = (e: { target: any; }, index: number) => {
        const target = e.target;
        const name: keyof typeof currSchedule = target.name;

        const newSchedule = [...schedule]
        const currSchedule = newSchedule[index];

        if(name === 'dayOfWeek' || name === 'openingTime' || name === 'closingTime') {
            currSchedule[name] = target.value
        } else if (name === 'closed') {
            currSchedule[name] = target.checked
        };
        setSchedule(newSchedule)
    };    

    return (
        <form
            onSubmit={handleSaveSchedule}
            className="flex flex-col gap-4 justify-center items-center max-w-[500px] mx-auto"
        >
            <div className="flex flex-col gap-4 justify-center items-center w-full">
                {schedule?.map((item: any, index: number) => (
                    <div
                        key={index}
                        className={`input-form flex items-center justify-between w-full ${
                            index === schedule.length - 1 ? "border-b pb-4" : ""
                        }`}
                    >
                        <select
                            className="bg-[#eff0f6] border-[#eff0f6] p-4 rounded-md"
                            name="dayOfWeek"
                            value={item.dayOfWeek}
                            onChange={(e) => handleEditChange(e, index)}
                            // disabled={true}
                        >
                            {[
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday",
                            ].map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        <input
                            type="time"
                            name="openingTime"
                            className="border border-[#80808045] p-[10px] rounded-md"
                            value={item.openingTime}
                            onChange={(e) => handleEditChange(e, index)}
                            // readOnly={true}
                        />
                        -
                        <input
                            type="time"
                            name="closingTime"
                            className="border border-[#80808045] p-[10px] rounded-md"
                            value={item.closingTime}
                            onChange={(e) => handleEditChange(e, index)}
                            // readOnly={true}
                        />
                        <Tooltip
                            hasArrow
                            label={`Business ${
                                item.closed ? "Closed" : "Open"
                            }`}
                            color="white"
                            placement="top"
                            padding={2}
                            className="bg-primary-500 rounded-md"
                        >
                            <div>
                                <PrimaryCheckBox
                                    name="closed"
                                    value={item.closed}
                                    isChecked={item.closed}
                                    onChange={(e) => handleEditChange(e, index)}
                                    size={"lg"}
                                    formControlProps={{ className: "w-auto" }}
                                />
                            </div>
                        </Tooltip>
                        <button
                            type="button"
                            className="border-none"
                            onClick={() => deleteClassFromSchedule(index)}
                        >
                            {/* <TrashIcon /> */}
                            <BiTrash className=" text-2xl text-red-500" />
                            {/* DEL */}
                        </button>
                        {/* )} */}
                    </div>
                ))}
            </div>
            <div className="input-form flex items-center justify-between w-full">
                <select
                    className="bg-[#eff0f6] border-[#eff0f6] p-4 rounded-md"
                    name="dayOfWeek"
                    value={newScheduleDays.dayOfWeek}
                    onChange={handleChange}
                >
                    {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                    ].map((day) => (
                        <option key={day} value={day}>
                            {day}
                        </option>
                    ))}
                </select>
                <input
                    type="time"
                    name="openingTime"
                    className="border border-[#80808045] p-[10px] rounded-md"
                    value={newScheduleDays.openingTime}
                    onChange={handleChange}
                />
                -
                <input
                    type="time"
                    name="closingTime"
                    className="border border-[#80808045] p-[10px] rounded-md"
                    value={newScheduleDays.closingTime}
                    onChange={handleChange}
                />
                <Tooltip
                    hasArrow
                    label={`${
                        newScheduleDays.closed
                            ? "Set business to open"
                            : "Set business to closed"
                    }`}
                    color="white"
                    placement="top"
                    padding={2}
                    className="bg-primary-500 rounded-md"
                >
                    <div>
                        <PrimaryCheckBox
                            name="closed"
                            isChecked={newScheduleDays.closed}
                            onChange={handleChange}
                            size={"lg"}
                            formControlProps={{ className: "w-auto" }}
                        />
                    </div>
                </Tooltip>
                <button
                    type="button"
                    className=" text-primary-400 rounded px-1"
                    onClick={addClassToSchedule}
                >
                    Add
                </button>
            </div>
        </form>
    );
};
