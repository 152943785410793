import React from "react";
import style from "../../styles/forum.module.scss";
import { PrimaryButton } from "components/index";
import { BiPlus } from "react-icons/bi";
import forumHero from "assets/svg/forumHero.svg";
import { Link } from "react-router-dom";

export const ForumHero = () => {
    return (
        <div className={style.forumHero}>
            <div className="container flex justify-between items-center md:flex-row flex-col gap-[30px]">
                <div className="md:w-[50%] text-wrap py-3">
                    <h1 className="text-6xl my-5">Forum Discussions</h1>
                    <p className="text-wrap md:w-[68%] my-5">
                        Create a thread to ask questions about products, discuss
                        and review products and businesses, whether or not they
                        are registered on Trade Tracka.
                    </p>
                    <Link
                        // onClick={() => toast("Feature coming soon! 🛍")}
                        // to="#"
                        to="/create-thread"
                    >
                        <PrimaryButton className="btn-lg text-primary-500 !bg-white">
                            <BiPlus /> <span>Start a thread</span>
                        </PrimaryButton>
                    </Link>
                </div>
                <div className="w-50">
                    <img alt="" src={forumHero} />
                </div>
            </div>
        </div>
    );
};
